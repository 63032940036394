import { CanActivate, Router, UrlTree } from '@angular/router'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { UserProviderService } from 'app/services/userProvider/user-provider.service'

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
	constructor(
		private userProvider: UserProviderService,
		private router: Router
	) {}

	canActivate():
		| boolean
		| Promise<boolean | UrlTree>
		| Observable<boolean | UrlTree> {
		if (this.userProvider?.loggedInUser?.id) {
			return true
		} else {
			this.router.navigate(['/auth'])
			return false
		}
	}
}
