<header class="header-wraper">
	<div class="container-fluid">
		<ion-row class="row">
			<!--logo-->
			<ion-col sizeMd="3" sizeSm="6" sizeXs="6">
				<div class="site-logo-wrap" data-aos="slide-down">
					<a href="https://tangychef.com">
						<img
							src="https://tangychef.com/wp-content/uploads/2023/09/tangychef-logo.png"
							alt=""
							title="tangy chef logo"
						/>
					</a>
				</div>
			</ion-col>

			<!--navigation-->
			<ion-col sizeMd="9" sizeSm="6" sizeXs="6">
				<div
					class="header-nav-wrap nav-underline"
					data-aos="slide-left"
				>
					<ul>
						<li><a href="https://tangychef.com/">Home</a></li>
						<li>
							<a href="https://user.tangychef.com/dishes"
								>Dishes</a
							>
						</li>
						<li>
							<a href="https://user.tangychef.com/grocery"
								>Grocery</a
							>
						</li>
						<li>
							<a href="https://user.tangychef.com/chefs">Chefs</a>
						</li>
						<li>
							<a href="https://vendor.tangychef.com/"
								>Sell With Us</a
							>
						</li>

						<li class="sign-btn-header">
							<a href="https://user.tangychef.com/auth">
								SIGN IN
							</a>
						</li>
					</ul>
				</div>
			</ion-col>
		</ion-row>
	</div>
</header>
