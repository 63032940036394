import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { AppModule } from './app/app.module'
import { environment } from './environments/environment'
import { defineCustomElements } from '@ionic/pwa-elements/loader'

if (environment.production) {
	enableProdMode()
	console.log('prod')
	window.console.log = function () {} // disable any console.log debugging statements in production mode
} else {
	console.log('qa')
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => console.log('err->', err))

// Call the loader
defineCustomElements(window)
