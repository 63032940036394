import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'
import { AuthGuard } from './guards/auth.guard'

const mobRoutes: Routes = [
	{
		path: 'auth',
		loadChildren: () =>
			import('./pages/auth/auth.module').then((m) => m.AuthPageModule),
	},

	{
		path: '',
		loadChildren: () =>
			import('./pages/choose-meals/choose-meals.module').then(
				(m) => m.ChooseMealsPageModule
			),
	},

	{
		path: 'verify-email/:id',
		loadChildren: () =>
			import('./pages/verify-email/verify-email.module').then(
				(m) => m.VerifyEmailPageModule
			),
	},

	{
		path: 'browse',
		loadChildren: () =>
			import('./pages/browse/browse-meals.module').then(
				(m) => m.browseModule
			),
	},

	{
		path: 'chef/:name/:id/:theme',
		loadChildren: () =>
			import('./pages/about-chef/chef.module').then(
				(m) => m.MobChefPageModule
			),
	},

	{
		path: 'chef/:name/:id',
		loadChildren: () =>
			import('./pages/about-chef/chef.module').then(
				(m) => m.MobChefPageModule
			),
	},

	{
		path: 'profile',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pages/profile/profile.module').then(
				(m) => m.MobProfilePageModule
			),
	},

	{
		path: 'checkout',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pages/checkout/checkout.module').then(
				(m) => m.MobCheckoutPageModule
			),
	},

	{
		path: 'checkout/:id',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pages/checkout/checkout.module').then(
				(m) => m.MobCheckoutPageModule
			),
	},

	{
		path: 'paymentMethod',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pages/paymentmethod/paymentmethod.module').then(
				(m) => m.PaymentmethodPageModule
			),
	},
	{
		path: 'orders',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pages/orders/orders.module').then(
				(m) => m.OrdersPageModule
			),
	},
	{
		path: 'card-edit/:id',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./components/card-edit/card-edit.module').then(
				(m) => m.CardEditModule
			),
	},

	{
		path: 'edit',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pages/edit/edit.module').then((m) => m.EditPageModule),
	},
	{
		path: 'dietery-prefences',
		loadChildren: () =>
			import('./pages/dietery-prefences/dietery-prefences.module').then(
				(m) => m.DieteryPrefencesPageModule
			),
	},
	{
		path: 'my-addresses',
		loadChildren: () =>
			import('./pages/my-addresses/my-addresses.module').then(
				(m) => m.MyAddressesPageModule
			),
	},
	{
		path: 'reset',
		loadChildren: () =>
			import('./pages/reset/reset.module').then((m) => m.ResetPageModule),
	},
	{
		path: 'pop-meal',
		loadChildren: () =>
			import('./pages/meal-pop/meal-pop.module').then(
				(m) => m.PopMealPageModule
			),
	},

	{
		path: 'meal/:name/:id',
		loadChildren: () =>
			import('./pages/meal/meal.module').then((m) => m.MealPageModule),
	},

	{
		path: 'history',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pages/history/history.module').then(
				(m) => m.HistoryPageModule
			),
	},

	{
		path: 'cart-list',
		loadChildren: () =>
			import('./pages/cart-list/cart-list.module').then(
				(m) => m.CartListPageModule
			),
	},
	{
		path: 'list-dishes',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./components/list-dishes/list-dishes.module').then(
				(m) => m.ListDishesModule
			),
	},

	{
		path: 'list-dishes/:id',
		loadChildren: () =>
			import('./components/list-dishes/list-dishes.module').then(
				(m) => m.ListDishesModule
			),
	},

	{
		path: 'list-dishes/:id/:vendorIndex/:accountIndex/:locationIndex',
		loadChildren: () =>
			import('./components/list-dishes/list-dishes.module').then(
				(m) => m.ListDishesModule
			),
	},
	{
		path: 'rev-dish',
		loadChildren: () =>
			import('./components/rev-dish/rev-dish.module').then(
				(m) => m.RevDishModule
			),
	},
	{
		path: 'verify',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pages/verify/verify.module').then(
				(m) => m.VerifyPageModule
			),
	},
	{
		path: 'chefs',
		loadChildren: () =>
			import('./pages/chefs/chefs.module').then((m) => m.ChefsPageModule),
	},
	{
		path: 'order-details/:id/:vendorId',
		loadChildren: () =>
			import('./pages/history/history.module').then(
				(m) => m.HistoryPageModule
			),
	},

	{
		path: 'confirm',
		loadChildren: () =>
			import('./pages/confirm/confirm.module').then(
				(m) => m.ConfirmPageModule
			),
	},

	{
		path: 'success',
		loadChildren: () =>
			import('./pages/success/success.module').then(
				(m) => m.SuccessPageModule
			),
	},

	{
		path: 'password-reset',
		loadChildren: () =>
			import('./pages/password-reset/password-reset.module').then(
				(m) => m.PasswordResetPageModule
			),
	},

	{ path: 'dishes', redirectTo: '', pathMatch: 'full' },
]

@NgModule({
	imports: [
		RouterModule.forRoot(mobRoutes, {
			preloadingStrategy: PreloadAllModules,
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
