import { Component, OnInit } from '@angular/core'
import { MealsService } from 'app/services/meals/meals.service'
import { UtilService } from 'app/services/util/util.service'

@Component({
	selector: 'app-nav-bottom',
	templateUrl: './nav-bottom.html',
	styleUrls: ['./nav-bottom.scss'],
})
export class NavBottom implements OnInit {
	constructor(public readonly ms: MealsService, private util: UtilService) {}

	ngOnInit() {}

	cart() {
		if (this.ms.cartCount > 0) {
			this.ms._menu = 'cart-list'
			this.util.navigateRoot('/cart-list')
		}
	}
}
