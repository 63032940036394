<ion-header>
	<ion-toolbar style="--background: #f5f5f5">
		<section
			class="flex f-just-btw f-align-center black"
			style="margin: 0 0.85rem"
		>
			<div *ngIf="userProvider._page == 'dishes' ">
				<span *ngIf="userProvider?.loggedInUser?.id" class="poppins-med"
					>Hi, {{userProvider.loggedInUser.firstName}}</span
				>
			</div>

			<span
				*ngIf="!userProvider?.loggedInUser?.id"
				class="poppins-med"
				routerLink="/auth"
				>Sign In</span
			>
		</section>
	</ion-toolbar>
</ion-header>
