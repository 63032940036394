import { Component, OnInit } from '@angular/core';
import { MealsService } from 'app/services/meals/meals.service';
import { UserProviderService } from 'app/services/userProvider/user-provider.service';

@Component({
  selector: 'app-header-web',
  templateUrl: './header-web.component.html',
  styleUrls: ['./header-web.component.scss'],
})
export class HeaderWebComponent implements OnInit {

  constructor(public readonly mealService: MealsService, public readonly userProvider: UserProviderService) { }

  ngOnInit() { }

}
