import { DatePipe } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { ApiService } from 'app/services/api/api.service'
import { MealsService } from 'app/services/meals/meals.service'
import { UserProviderService } from 'app/services/userProvider/user-provider.service'
import { UtilService } from 'app/services/util/util.service'
import * as moment from 'moment'
import * as _ from 'lodash'

@Component({
	selector: 'app-history',
	templateUrl: './history.page.html',
	styleUrls: ['./history.page.scss'],
})
export class HistoryPage implements OnInit {
	showPassword: boolean = false
	o: any = {}
	id
	vendorId
	parentCarts: any[] = []
	deliveryDate
	orderId
	hcList: any[] = []

	constructor(
		public userProvider: UserProviderService,
		private api: ApiService,
		private ar: ActivatedRoute,
		private util: UtilService,
		private datePipe: DatePipe,
		private mealService: MealsService
	) {}

	ngOnInit() {
		this.id = this.ar.snapshot.paramMap.get('id')
		this.vendorId = this.ar.snapshot.paramMap.get('vendorId')
		if (this.id) this.findOrder()
		else {
			this.userProvider._page = 'order-details'
			this.util.navigateRoot('auth')
			console.log('page -->', this.userProvider._page)
		}
	}

	findOrder() {
		this.util.showLoading('')
		this.api.getOrder(this.id).subscribe((res) => {
			this.o = res

			this.hcList = this.mealService.reGrOrderHistory(this.o?.order?.cart)
			console.log('1 HiSTORY +++++++=>', this.o?.order?.cart)
			console.log('2 HiSTORY +++++++=>', this.hcList)

			// filter if vendorId is available
			if (this.vendorId !== 'all') {
				const list = this.o.order.cart.filter(
					(el: any) => el.vendorId === this.vendorId
				)
				this.o.order.cart = list
			}

			console.log('order details ->', this.o)
			this.util.hideLoading()
			this.sqOrderDetails(res)
		}),
			(err: any) => {
				this.util.hideLoading()
				console.log('err ->', err)
			}
	}

	sqOrderDetails(el: any) {
		console.log('sq->', el)
		this.parentCarts = []

		if (
			el.squareOrder &&
			el.squareOrder.order &&
			el.squareOrder.order.carts &&
			el.squareOrder.order.carts.length > 0
		) {
			this.deliveryDate =
				el.squareOrder && el.squareOrder.deliveryDate
					? el.squareOrder.deliveryDate
					: ''

			this.id = el.id

			this.orderId = el.squareOrder?.hcSqorderId
				? el.squareOrder?.hcSqorderId
				: ''

			let list = []
			const c = el.squareOrder.order.carts

			c.forEach((i2: any) => {
				const locationName = i2.locationName
				let subtotal = 0
				if (i2.items && i2.items.length > 0) {
					i2.items.forEach((i3: any) => {
						subtotal += (i3.qty * i3.vp.price) / 100
					})

					const tax = Math.round(7.75 * subtotal) / 100
					list.push({
						items: i2.items,
						locationName,
						fulfillement: 'Pickup',
					})
				}
			})
			this.parentCarts = list
			console.log('1 sq parent sq carts -=>', this.parentCarts)
		}
	}

	calcDate(d) {
		return moment(d * 1000)
			.format('MM/DD/YYYY hh:mm a')
			.toString()
	}
}
