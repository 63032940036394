import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { ModalController } from '@ionic/angular'
import * as moment from 'moment'
import { ApiService } from 'app/services/api/api.service'
import { MealsService } from 'app/services/meals/meals.service'
import { UserProviderService } from 'app/services/userProvider/user-provider.service'
import { UtilService } from 'app/services/util/util.service'
import { combineLatest } from 'rxjs'
import { PhotoUploadService } from 'app/services/photo-service/photoservice.service'

export interface MyData {
	name: string
	filepath: string
	size: number
}

@Component({
	selector: 'app-chef',
	templateUrl: './chef.page.html',
	styleUrls: ['./chef.page.scss'],
})
export class ChefPage implements OnInit {
	meal
	parentMeal
	relatedMeals = []
	_switch = 'meals'
	_review = false
	readMoreText = -1
	readBio = false
	avgRating = 0
	vendor: any = {}
	vId
	themeId
	newGuest: any = { name: '', email: '' }

	constructor(
		public mealService: MealsService,
		private ar: ActivatedRoute,
		public util: UtilService,
		public sce: UserProviderService,
		public modalController: ModalController,
		private api: ApiService,
		public router: Router,
		private photoService: PhotoUploadService,
		private up: UserProviderService
	) {}

	dismiss() {
		this.modalController.dismiss({
			dismissed: true,
		})
	}

	async ngOnInit() {
		this.init()
		const u = this.sce.loggedInUser
		if(u.id){
			if ( u.active == false ){
			   await this.util.showAlert("user deactivated","Your account has been suspended. To resume Please conatct at help@tangychef.com")

			   setTimeout(() => {
				   this.sce.logout()
				   this.util.navigateForward("auth")
			   }, 5000);
			   
			   }
		   await this.sce.listenerActiveUser("users",u.id)
	   }
	}

	async ionViewWillEnter() {
		this.up._showReviewForm = false
		this.up._showMenuTheme = false
		this.init()
		const u = this.sce.loggedInUser
		if(u.id){
			if ( u.active == false ){
			   await this.util.showAlert("user deactivated","Your account has been suspended. To resume Please conatct at help@tangychef.com")

			   setTimeout(() => {
				   this.sce.logout()
				   this.util.navigateForward("auth")
			   }, 5000);
			   
			   }
		   await this.sce.listenerActiveUser("users",u.id)
	   }
	}

	init() {
		this.vId = this.ar.snapshot.paramMap.get('id')
		this.themeId = this.ar.snapshot.paramMap.get('theme')
		if (this.themeId) this.up._showMenuTheme = true

		this.sce._page = 'chef'
		if (this.vId) {
			this.initChef(this.vId)
		} else this.util.navigateRoot('dishes')
	}

	async initChef(vendorId) {
		await this.getData(vendorId)
		this.readMoreText = -1
		this.avgRating = this.calcAvgRating()
		// this.mealService.refreshCalendarList()
	}

	getData(vendorId) {
		this.util.showLoading('')
		return new Promise<any>((resolved, rejected) => {
			return (
				combineLatest([
					this.api.getMeals(vendorId),
					this.api.getVendor(vendorId),
				]).subscribe((res: any) => {
					console.log('res meals, vendor ===>', res[0], res[1])
					this.relatedMeals = res[0]
					this.vendor = res[1][0]

					this.relatedMeals.forEach((el) => {
						el.vendor = this.vendor
					})
					this.util.hideLoading()
					resolved(true)
				}),
				(err: any) => {
					this.util.hideLoading()
					console.log('loading all meal data ->', err)
				}
			)
		})
	}

	calcAvgRating(): number {
		let sum = 0
		let avg = 0
		if (
			this.vendor &&
			this.vendor.review &&
			this.vendor.review.length > 0
		) {
			this.vendor.review.forEach((el) => {
				sum = sum + el.rating
			})
			avg = Math.ceil(sum / this.vendor.review.length)
		}
		//console.log('vendor rating->', avg)
		return avg
	}

	save() {
		this.util.showLoading2('')
		const date = moment(new Date()).format('MM/DD/YYYY hh:mm a')
		this.sce.review.submitted = date
		this.sce.review.userId = this.sce?.loggedInUser.id
			? this.sce?.loggedInUser.id
			: ''

		if (this.vendor.review && this.vendor.review.length > 0)
			this.vendor.review.unshift(this.sce.review)
		else this.vendor.review = [this.sce.review]

		this.api
			.updateData('vendors', this.vendor.id, {
				review: this.vendor.review,
			})
			.subscribe(
				(res) => {
					this.util.hideLoading2()
					this.sce._showReviewForm = false
					//this.uril.showToast('Review Saved. Thank you.')
					this.sce.initReview(false)
					this.sce._showReviewForm = false
				},
				(err) => {
					this.util.showToast('Review not saved. Please try again.')
					this.util.hideLoading2()
				}
			)
		this.avgRating = this.calcAvgRating()
		console.log('new review', this.sce.review, this.vendor)
	}

	change(event: any) {
		this.sce.review.rating = event.detail.value
		console.log('current rating', this.sce.review.rating, event.detail)
	}

	readMore(j) {
		this.readMoreText = j
	}

	readLess() {
		this.readMoreText = -1
	}

	uploadPhoto(img: any) {
		this.util.showLoading2('')
		this.photoService
			.uploadPhotoPro(this.sce.loggedInUser.firstName + '-' + img)
			.subscribe(
				(url) => {
					this.util.hideLoading2()
					this.sce.review.photo = url
					console.log('review ->', this.sce.review)
				},

				(err) => {
					this.util.hideLoading2()
				}
			)
	}
}