import { Injectable } from '@angular/core'
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore'
import firebase from 'firebase/compat/app'
import 'firebase/firestore'
import { Observable } from 'rxjs'
import { take } from 'rxjs/operators'
import { BaseDatabaseModel } from './base-dto.model'

@Injectable()
export class FirestoreService {
	userid: any

	constructor(public store: AngularFirestore) {
		this.userid = 1
	}

	public find<T extends BaseDatabaseModel>(
		collection: string,
		queryFn: QueryFn
	): Observable<T[]> {
		return new Observable((observer) => {
			this.store
				.collection<T>(collection, queryFn)
				.valueChanges({ idField: 'id' })
				.pipe(take(1))
				.subscribe(
					(docs) => {
						observer.next(docs)
					},
					(err) => {
						observer.error(err)
					}
				)
		})
	}

	public update<T extends BaseDatabaseModel>(
		collection: string,
		id: string,
		document: Partial<T>
	): Promise<void> {
		return this.store
			.doc<T>(`${collection}/${id}`)
			.update(this.addUpdatedAt(document))
	}

	public createWithId<T extends BaseDatabaseModel>(
		collection: string,
		data: T
	): Promise<void> {
		return this.store
			.doc<T>(`${collection}/${data.id}`)
			.set(this.addCreatedAt(data))
	}

	public async create<T extends BaseDatabaseModel>(
		collection: string,
		data: T
	): Promise<
		firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
	> {
		const doc = await this.store
			.collection<T>(collection)
			.add(this.addCreatedAt(data))
		return doc.get()
	}

	public getOne<T extends BaseDatabaseModel>(
		collection: string,
		id: string
	): Observable<T> {
		return this.store
			.doc<T>(`${collection}/${id}`)
			.valueChanges()
			.pipe(take(1))
	}

	public uploadFile(
		folderName: string,
		downloadUrl: string,
		fileName: string
	): Promise<any> {
		return this.store
			.collection<{ downloadUrl: string; fileName: string; uid: string }>(
				`fileReferences`
			)
			.add({ downloadUrl, fileName, uid: this.userid })
	}

	addCreatedAt(data) {
		return {
			...data,
			createdAt: firebase.firestore.FieldValue.serverTimestamp(),
			updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
		}
	}
	addUpdatedAt(data) {
		return {
			...data,
			updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
		}
	}
}

export interface FirestoreQuery {
	field: string
	operation: firebase.firestore.WhereFilterOp
	searchKey: string
}
