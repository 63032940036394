<form novalidate (ngSubmit)="createPm()" [formGroup]="stripeTest">
	<div class="form-item">
		<ion-label position="stacked" color="dark">Name on Card *</ion-label>
		<ion-input
			name="fullname"
			type="text"
			formControlName="name"
			placeholder="Name on the card"
			value="{{sce.loggedInUser.lastName}} {{sce.loggedInUser.firstName}}"
		></ion-input>
	</div>

	<div class="form-item">
		<ion-label position="stacked" color="dark">Card Details *</ion-label>
		<div class="stripe-card">
			<ngx-stripe-card
				containerClass="stripeForm"
				[options]="cardOptions"
				[elementsOptions]="elementsOptions"
			></ngx-stripe-card>
		</div>
	</div>

	<div class="flex-rowcenter">
		<ion-button class="orange-btn" type="submit">
			<span>Save Card</span>
		</ion-button>
	</div>
</form>
