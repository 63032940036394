<ion-app>
	<ion-menu
		side="end"
		contentId="main"
		[maxEdgeStart]="0"
		class="my-custom-menu"
	>
		<ion-content>
			<ion-menu-toggle>
				<ion-content>
					<!--------------------User Menu ------>
					<ion-list
						lines="none"
						class="margin-top-3"
						*ngIf="userProvider?.loggedInUser?.id"
					>
						<ion-menu-toggle *ngFor="let link of links">
							<ion-item
								class="side-menu"
								(click)="gotoPage(link.location)"
							>
								{{ link.name }}
							</ion-item>
						</ion-menu-toggle>

						<!---------------Sell With Us----->

						<ion-menu-toggle>
							<ion-item>
								<a
									href="https://vendor.tangychef.com"
									class="side-menu"
									>Sell With Us</a
								>
							</ion-item>
						</ion-menu-toggle>

						<ion-menu-toggle>
							<ion-item
								(click)="
									userProvider?.loggedInUser?.id
										? logout()
										: navigate('/auth', true)
								"
							>
								<span class="side-menu">
									{{
										userProvider?.loggedInUser?.id
											? 'Sign Out'
											: 'Sign In'
									}}
								</span>
							</ion-item>
						</ion-menu-toggle>
					</ion-list>

					<!--------------------public Menu ------>
					<ion-list
						lines="none"
						class="margin-top-3"
						*ngIf="!userProvider?.loggedInUser?.id"
					>
						<ion-menu-toggle>
							<ion-item
								class="side-menu"
								(click)="gotoPage(links[4].location)"
							>
								{{ links[4].name }}
							</ion-item>
						</ion-menu-toggle>

						<ion-menu-toggle>
							<ion-item>
								<a
									href="https://vendor.tangychef.com"
									class="side-menu"
									>Sell With Us</a
								>
							</ion-item>
						</ion-menu-toggle>

						<ion-menu-toggle>
							<ion-item (click)="navigate('/auth', true)">
								<span class="side-menu"> Sign In </span>
							</ion-item>
						</ion-menu-toggle>
					</ion-list>
				</ion-content>
			</ion-menu-toggle>
		</ion-content>
	</ion-menu>

	<ion-router-outlet id="main"></ion-router-outlet>
</ion-app>
