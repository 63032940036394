<app-header-new-menu class="show-big-header"></app-header-new-menu>

<ion-content class="f-regular relative">
	<!---->
	<ion-header
		[translucent]="true"
		class="show-small-header"
		[ngClass]="{'ios-margin': sce._isIos}"
	>
		<ion-toolbar class="relative">
			<ion-buttons class="absolute" slot="start" (click)="util.back()">
				<ion-button>
					<img
						class="back-btn"
						src="assets/icons/left-arrow.svg"
						alt="Back Arrow"
					/>
				</ion-button>
			</ion-buttons>
		</ion-toolbar>
	</ion-header>

	<div>
		<div>
			<app-temp0
				*ngIf="vendor?.template == 0"
				[vendor]="vendor"
				[relatedMeals]="relatedMeals"
			></app-temp0>
			<app-temp1
				*ngIf="vendor?.template == 1"
				[vendor]="vendor"
				[relatedMeals]="relatedMeals"
			></app-temp1>
			<app-temp2
				*ngIf="vendor?.template == 2"
				[vendor]="vendor"
				[relatedMeals]="relatedMeals"
			></app-temp2>
			<app-temp3
				*ngIf="vendor?.template == 3"
				[vendor]="vendor"
				[relatedMeals]="relatedMeals"
			></app-temp3>
			<app-temp4
				*ngIf="vendor?.template == 4"
				[vendor]="vendor"
				[relatedMeals]="relatedMeals"
			></app-temp4>
			<app-temp5
				*ngIf="vendor?.template == 5"
				[vendor]="vendor"
				[relatedMeals]="relatedMeals"
			></app-temp5>
		</div>
	</div>

	<!-- -------------------Create a Review ----------------------------------->

	<section>
		<div *ngIf="sce._showReviewForm" class="template-container-1">
			<h5 class="orange-dark center">Create a Review...</h5>

			<div
				class="margin-top-1 border"
				style="
					max-width: 950px;
					margin: 1rem auto;
					background-color: white;
				"
			>
				<form #rateForm="ngForm">
					<div class="center">
						Tell people what you think.
						<br />Help others by sharing your experience with this
						business.
					</div>

					<ion-row>
						<ion-col>
							<ion-range
								min="0"
								max="5"
								snaps="true"
								ticks="true"
								color="secondary"
								[value]="sce.review.rating"
								pin="true"
								(ionChange)="change($event)"
							>
								<ion-label slot="start">
									<ion-icon
										name="star"
										class="rating-02"
									></ion-icon
									>0
								</ion-label>
								<ion-label slot="end">
									<ion-icon
										name="star"
										class="rating-01"
									></ion-icon
									>5 &nbsp;
								</ion-label>
							</ion-range>
						</ion-col>
						<ion-col class="flex-row-2">
							<span class="center grey margin-top-1"
								>Rating:
								<strong class="orangex"
									>{{sce.review.rating}}</strong
								>
								/ 5</span
							>
						</ion-col>
					</ion-row>

					<ion-row
						><ion-col style="margin-left: 1.4rem">
							<div class="input-group">
								<ion-label for="name" position="fixed">
									Name<span class="orange-dark strong"
										>*</span
									>
								</ion-label>
								<div
									class="input-container"
									style="max-width: 300px"
								>
									<ion-input
										name="name"
										type="text"
										required
										ngModel
										minlength="2"
										disabled
										[(ngModel)]="sce.review.name"
									></ion-input>
								</div>
							</div>

							<div class="input-group">
								<ion-label for="name" position="fixed">
									Email
									<span class="small-font orangex"
										>(Email will not be published.)</span
									>
								</ion-label>
								<div
									class="input-container"
									style="max-width: 300px"
								>
									<ion-input
										name="email"
										type="email"
										pattern="[A-Za-z0-9._%+\-]+@[A-Za-z0-9.\-]+\.[A-Za-z]{2,}$"
										ngModel
										minlength="7"
										disabled
										[(ngModel)]="sce.review.email"
									></ion-input>
								</div>
							</div>

							<div class="margin-top-1 flex-rowcenter">
								<ion-button
									class="orange-btn"
									(click)="uploadPhoto('chefReview')"
								>
									Add a Photo
								</ion-button>

								<img
									*ngIf="sce.review.photo"
									[src]="sce.review?.photo"
									class="img-cover-square"
								/>
							</div>

							<ion-item class="ii margin-top-2">
								<ion-textarea
									name="message"
									type="text"
									required
									ngModel
									minlength="10"
									placeholder="Add your review here"
									class="text-area"
									[(ngModel)]="sce.review.message"
								>
								</ion-textarea>
							</ion-item> </ion-col
					></ion-row>

					<div class="flex-rowcenter margin-top-1">
						<ion-button
							class="orange-btn"
							[ngClass]="rateForm.valid ? '' : 'invalid'"
							[disabled]="rateForm.valid ? false : true"
							(click)="save()"
						>
							<span>Save</span>
						</ion-button>

						<ion-button
							fill="clear"
							class="underline"
							(click)="sce.initReview(false)"
						>
							Cancel
						</ion-button>
					</div>
				</form>
			</div>
		</div>
	</section>

	<footer-component></footer-component>
	<app-nav-bottom></app-nav-bottom>
</ion-content>
