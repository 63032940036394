import { Injectable } from '@angular/core'
import { Storage } from '@ionic/storage-angular'
import { ApiService } from '../api/api.service'
import { Observable } from 'rxjs'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { timeout, retry } from 'rxjs/operators'
import { environment } from 'environments/environment'
import { UtilService } from '../util/util.service'
import { AuthenticationService } from '../api/firebase-authentication.service'
import { Capacitor } from '@capacitor/core'
import { AngularFirestore } from '@angular/fire/compat/firestore'
import { Platform } from '@ionic/angular'

@Injectable({
	providedIn: 'root',
})
export class UserProviderService {
	loginMode: boolean = true
	public loggedInUser
	id //user id for Auto Login
	_navFromCheckoutToBilling = false
	_showReviewForm = false
	_showMenuTheme = false
	review: any = {}
	_showUserMenu = false
	_spinner = false
	_page = 'dishes'
	_isIos = false

	constructor(
		public storage: Storage,
		private api: ApiService,
		private http: HttpClient,
		private util: UtilService,
		private auth: AuthenticationService,
		private store: AngularFirestore,
		private platform: Platform
	) {
		this._isIos = this.platform.is('ios')
		this.createNewEmptyUser()
	}

	createNewEmptyUser() {
		this.loggedInUser = {
			id: '',
			email: '',
			addressState: '',
			addressCity: '',
			addressLine1: '',
			addressLine2: '',
			firstName: '',
			lastName: '',
			phoneNumber: '',
			addressZipCode: '',
		}
	}

	async load() {
		await this.storage.create()
		return new Promise(async (resolve, reject) => {
			try {
				const id = await this.storage.get('tid')
				if (id) {
					this.id = id
					this.api.getUser(id).subscribe(
						async (user: any) => {
							if (user) {
								if (!user.shippingList) user.shippingList = []
								this.setLoggedInUser(user)
								resolve(true)
							} else {
								await this.storage.remove('tid')
								this.accessRouting()
								resolve(false)
							}
						},
						(err) => {
							resolve(false)
							console.log('1- Load user err -->', err)
						}
					)
				} else {
					// this.accessRouting()
					resolve(true)
				}
			} catch (e) {
				resolve(false)
				console.log('2- Load user err -->', e)
			}
		})
	}

	accessRouting() {
		console.log('is web platform -> ', Capacitor.getPlatform() === 'web')
		if (Capacitor.getPlatform() !== 'web') this.util.navigateRoot('auth')
		else this.util.navigateRoot('dishes')
	}

	async setLoggedInUser(user: any) {
		this.loggedInUser = user
		console.log('logged In User -->', this.loggedInUser)
	}

	async logout(): Promise<any> {
		this.createNewEmptyUser()
		this.loggedInUser = {}
		await this.auth.logout()
		await this.storage.remove('tid')
		location.reload()
	}

	/**
	 * create customer
	 */
	createCustomer(data: any, url: string): Observable<any> {
		const httpOptions = {
			headers: new HttpHeaders({
				async: 'true',
				crossDomain: 'true',
				'content-type': 'application/x-www-form-urlencoded',
				authorization: 'Bearer ' + environment.stripe.secret,
				'cache-control': 'no-cache',
				'Access-Control-Allow-Origin': '*',
			}),
		}
		const params = new HttpParams()
			.set('description', data.description)
			.set('email', data.email)
			.set('phone', data.phone)
			.set('name', data.name)
		return this.http
			.post(url, params, httpOptions)
			.pipe(timeout(6000), retry(1))
	}

	/**
	 * check customer
	 */
	checkCustomer(url: string): Observable<any> {
		const httpOptions = {
			headers: new HttpHeaders({
				async: 'true',
				crossDomain: 'true',
				'content-type': 'application/json',
				authorization: 'Bearer ' + environment.stripe.secret,
				'cache-control': 'no-cache',
				'Access-Control-Allow-Origin': '*',
			}),
		}

		return this.http
			.post(url, {}, httpOptions)
			.pipe(timeout(6000), retry(1))
	}

	/**-------------------------------------------------------------
	 *
	 * @param data
	 * @param url
	 * @returns
	 */
	attachPmToCustomer(data: any, url: string): Observable<any> {
		const httpOptions = {
			headers: new HttpHeaders({
				async: 'true',
				crossDomain: 'true',
				'content-type': 'application/x-www-form-urlencoded',
				authorization: 'Bearer ' + environment.stripe.secret,
				'cache-control': 'no-cache',
				'Access-Control-Allow-Origin': '*',
			}),
		}
		const params = new HttpParams().set('customer', data.customer)
		return this.http
			.post(url, params, httpOptions)
			.pipe(timeout(6000), retry(1))
	}

	/**----------------------------------------------------------------
	 *
	 * @param data
	 * @param url
	 * @returns
	 */
	stripePay(data: any, url: string): Observable<any> {
		const httpOptions = {
			headers: new HttpHeaders({
				async: 'true',
				crossDomain: 'true',
				'content-type': 'application/x-www-form-urlencoded',
				authorization: 'Bearer ' + environment.stripe.secret,
				'cache-control': 'no-cache',
				'Access-Control-Allow-Origin': '*',
			}),
		}
		const params = new HttpParams()
			.set('amount', data.amount) //data.amount)
			.set('currency', data.currency)
			.set('customer', data.customer)
			.set('payment_method', data.payment_method)
			.set('confirm', data.confirm)
			.set('return_url', data.return_url)

		return this.http
			.post(url, params, httpOptions)
			.pipe(timeout(6000), retry(1))
	}

	/**----------------------------------------------------------
	 *
	 * @param data
	 * @param url
	 * @returns
	 */
	stripeAuthorize(data: any, url: string): Observable<any> {
		const httpOptions = {
			headers: new HttpHeaders({
				async: 'true',
				crossDomain: 'true',
				'content-type': 'application/x-www-form-urlencoded',
				authorization: 'Bearer ' + environment.stripe.secret,
				'cache-control': 'no-cache',
				'Access-Control-Allow-Origin': '*',
			}),
		}
		const params = new HttpParams()
			.set('amount', data.amount) //data.amount)
			.set('currency', data.currency)
			.set('customer', data.customer)
			.set('payment_method', data.payment_method)
			.set('capture_method', data.capture_method)

		return this.http
			.post(url, params, httpOptions)
			.pipe(timeout(6000), retry(1))
	}

	/**----------------------------------------------------------
	 *
	 * @param data
	 * @param url
	 * @returns
	 */
	stripeCapture(data: any, url: string): Observable<any> {
		const httpOptions = {
			headers: new HttpHeaders({
				async: 'true',
				crossDomain: 'true',
				'content-type': 'application/x-www-form-urlencoded',
				authorization: 'Bearer ' + environment.stripe.secret,
				'cache-control': 'no-cache',
				'Access-Control-Allow-Origin': '*',
			}),
		}
		const params = new HttpParams()

		return this.http
			.post(url, data, httpOptions)
			.pipe(timeout(6000), retry(1))
	}

	/**------------------------------------------------------------
	 *
	 * @param b
	 * @returns
	 */
	initReview(b: boolean) {
		if (!this.loggedInUser.id) {
			this.util.alert(
				'Note',
				'Please sign in or sign up, and then add your reviews.'
			)
			this.util.navigateRoot('auth')
			return
		}
		this._showReviewForm = b
		this.review = {
			rating: 5,
			name: this.loggedInUser
				? this.loggedInUser.firstName + ' ' + this.loggedInUser.lastName
				: '',
			email: this.loggedInUser.email,
			photo: '',
			message: '',
		}
	}

	/**----------------------------------------------------------------
	 *
	 * @param collection
	 * @param userId
	 */
	listening
	async listenerActiveUser(collection: string, userId: string) {
		try {
			const apptCollection = this.store.collection<any>(
				collection,
				(ref) => ref.where('id', '==', userId)
			)

			this.listening = apptCollection
				.snapshotChanges(['added', 'modified'])
				.subscribe(async (action: any) => {
					console.log('listner ->', action[0].type)
					if (action[0]?.type === 'modified') {
						const user: any = await this.api.getOneRec(
							'users',
							userId
						)
						if (user) {
							this.loggedInUser.active = user?.active ?? true
							if (user.active == false) {
								await this.util.showAlert(
									'User Inactive',
									'Your account is inactive. Please contact the Help Center for assistance.'
								)

								setTimeout(() => {
									this.logout()
								}, 5000)
							}
							// if (this.listening) this.listening.unsubscribe()

							console.log('after auth->', user, this.loggedInUser)
						}
					}
				})
		} catch (e) {
			console.log('Abort Listener', e)
		}
	}
}
