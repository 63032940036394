import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { MealsService } from 'app/services/meals/meals.service'
import { UserProviderService } from 'app/services/userProvider/user-provider.service'
import { UtilService } from 'app/services/util/util.service'

@Component({
	selector: 'app-header-new-menu',
	templateUrl: './header-new-menu.component.html',
	styleUrls: ['./header-new-menu.component.scss'],
})
export class HeaderNewMenuComponent implements OnInit {
	@Input() zip = false
	@Input() cart = true
	@Input() profile = true
	@Input() search = false
	@Input() page = ''
	@Output() stt = new EventEmitter<any>()

	constructor(
		public mealService: MealsService,
		public readonly userProvider: UserProviderService,
		public readonly util: UtilService
	) {}

	ngOnInit() {}
	ionViewWillEnter() {}

	scrollToTop() {
		this.stt.emit({})
	}
}
