import { Component, Input, OnInit } from '@angular/core'
import { ActivatedRoute, Params } from '@angular/router'
import { ModalController } from '@ionic/angular'
import { ApiService } from 'app/services/api/api.service'
import { MealsService } from 'app/services/meals/meals.service'
import { UserProviderService } from 'app/services/userProvider/user-provider.service'
import { UtilService } from 'app/services/util/util.service'

@Component({
	selector: 'app-meal-pop',
	templateUrl: './meal-pop.page.html',
	styleUrls: ['./meal-pop.page.scss'],
})
export class PopMealPage implements OnInit {
	meal: any = {}
	avgRating = 0
	relatedMeals: any[] = []
	review: any = {}
	_all = false
	constructor(
		public mealService: MealsService,
		private util: UtilService,
		public userProvider: UserProviderService,
		public modalController: ModalController,
		private api: ApiService,
		private ar: ActivatedRoute
	) {}

	ngOnInit() {
		if (this.mealService.selectedMeal?.id) this.findMeal()
		else this.util.navigateRoot('dishes')
	}

	/**
	 *
	 */
	findMeal() {
		this.userProvider._spinner = true
		try {
			this.api
				.getMeal(this.mealService.selectedMeal?.id)
				.subscribe((res) => {
					this.meal = res
					this.util.hideLoading()
					if (!this.meal.vendor) {
						this.api.getVendor(this.meal.vendorId).subscribe(
							(vendor) => {
								this.meal.vendor = vendor[0]
								console.log('meal -------------->', this.meal)

								if (
									!this.mealService.data.ingredients ||
									this.mealService.data.ingredients.length ===
										0
								) {
									this.api.getList('ingredients').subscribe(
										(ingr) => {
											this.mealService.data.ingredients =
												ingr
											console.log('ingredients ->', ingr)
										},
										(err) => {
											console.log('err ->', err)
										}
									)
								}
							},
							(err) => {
								console.log('err ->', err)
							}
						)
					}
				})
			this.userProvider._spinner = false
		} catch (e) {
			this.userProvider._spinner = false
			console.log('err ->', e)
		}
	}

	calcAvgRating(): number {
		let sum = 0
		let avg = 0
		if (this.meal.review && this.meal.review.length > 0) {
			this.meal.review.forEach((el) => {
				sum = sum + el.rating
			})
			avg = Math.ceil(sum / this.meal.review.length)
		}
		console.log('vendor rating->', avg)
		return avg
	}

	getFavorite(mealId: string) {
		if (!this.userProvider.loggedInUser.id) return
		let fav: any
		let favorites: any[] = this.userProvider.loggedInUser.favorites
		if (favorites && favorites.length > 0) {
			fav = this.userProvider.loggedInUser.favorites.find(
				(el) => el.mealId === mealId
			)
		}
		return fav && fav.checked ? true : false
	}

	async updateFavorite(meal: any) {
		if (!this.userProvider.loggedInUser.id) {
			this.util.alert(
				'Note',
				'Please sign in to add your favorite dishes.'
			)
			return
		}

		let favorites: any[] = this.userProvider.loggedInUser.favorites
		let found = false
		if (favorites && favorites.length > 0) {
			const idx = favorites.findIndex((el) => el.mealId === meal.id)
			if (idx >= 0) {
				found = true
				favorites[idx].checked = !favorites[idx].checked
			} else {
				favorites.push({ mealId: meal.id, checked: true })
			}
		} else favorites = [{ mealId: meal.id, checked: true }]

		console.log('favorites -->', favorites)
		this.userProvider.loggedInUser.favorites = favorites
		this.api.updateUser(this.userProvider.loggedInUser.id, {
			favorites: favorites,
		})
	}

	getIngredient(id: string) {
		const ingr = this.mealService.data.ingredients.find(
			(el: any) => el.id === id
		)
		return ingr && ingr.name ? ingr.name : ''
	}

	viewChef(v: any) {
		this.mealService.selectedVendor = v
		this.mealService._chefPopup = true
		this.mealService.openModal('ChefPage')
	}

	get getMealReviews() {
		return this.meal?.review && this.meal?.review.length > 0
			? this.meal?.review
			: []
	}

	get getvendorReviews() {
		return this.meal?.vendor?.review && this.meal?.vendor?.review.length > 0
			? this.meal?.vendor?.review
			: []
	}

	addReview() {
		if (this.userProvider.loggedInUser.id) {
			this.mealService._showAddReview = !this.mealService._showAddReview
			this.review = {
				rating: 5,
				name: this.userProvider.loggedInUser
					? this.userProvider.loggedInUser.firstName +
					  ' ' +
					  this.userProvider.loggedInUser.lastName
					: '',
				email: this.userProvider.loggedInUser.email,
				photo: '',
				message: '',
			}
		} else
			this.util.alert(
				'Note',
				'Please sign in or sign up, and then add your reviews.'
			)
		return
	}

	change(event: any, meal: any) {
		meal.spiceLevel = Number(event.target.value)
		console.log('spice level ->', meal)
	}
}
