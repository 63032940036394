<app-header-new-menu></app-header-new-menu>

<!------------------------------------------------------>

<ion-content>
	<div class="center poppons-med padding-1 view-marg-top-1">
		<h4>Tangy Chef Order</h4>
	</div>

	<ion-row class="totals" *ngIf="o?.totals?.subTotal >0">
		<ion-col size-lg="6" size-md="6" size-sm="12" size-xs="12">
			<div *ngIf="o?.payment">
				<div class="margin-top-05 poppins-bold">
					Name: {{o.payment?.cc?.name}}
				</div>
				<div class="margin-top-05">
					Card:
					<span class="red">{{o?.payment?.cc?.brand}}...</span>
					<span class="red">{{o?.payment?.cc?.last4}}</span>
				</div>

				<div id="mealSubtitle">Payment Successful</div>
			</div>
		</ion-col>
		<ion-col size-lg="6" size-md="6" size-sm="12" size-xs="12">
			<div class="flex f-just-btw margin-top-05">
				<span>Subtotal</span>
				<span class="poppins-bold">
					{{o?.totals?.subTotal | currency}}</span
				>
			</div>
			<div class="flex f-just-btw margin-top-05">
				<span>Tips</span>
				<span class="poppins-bold">
					{{o?.totals?.tips | currency}}</span
				>
			</div>

			<div class="flex f-just-btw margin-top-05">
				<span>Delivery</span>
				<span class="poppins-bold">
					{{o?.totals?.delivery | currency}}</span
				>
			</div>

			<div class="flex f-just-btw margin-top-05">
				<span>Tax</span
				><span class="poppins-bold"
					>{{o?.totals?.tax | currency}}
				</span>
			</div>
			<div class="flex f-just-btw margin-top-05">
				<span>Total</span
				><span class="poppins-bold">
					{{o?.totals?.total | currency}}</span
				>
			</div>
		</ion-col>
	</ion-row>

	<!-- sq orders -->

	<section class="sq-order" *ngIf="parentCarts && parentCarts.length > 0">
		<ion-row class="order-header">
			<ion-col size-lg="6" size-md="6" size-sm="12" size-xs="12">
				Order Placed
				<span class="f-small red poppins-bold bold-500"
					>{{deliveryDate | date : 'MM/dd/yyyy hh:mm a' }}</span
				>
			</ion-col>

			<ion-col size-lg="6" size-md="6" size-sm="12" size-xs="12">
				Order #
				<strong
					><span class="red poppins-bold bold-500"
						>{{orderId}}</span
					></strong
				>
			</ion-col>
		</ion-row>

		<section>
			<div *ngFor="let loc of parentCarts">
				<section>
					<div
						class="margin-top-2 flex f-align-center f-just-btw padding-0-5"
					>
						<h4 class="poppins-bold">{{loc.locationName}}</h4>
						<div class="pickup">Pickup</div>
					</div>

					<!--------------------------start sub cart------------------>

					<section
						class="margin-1 padding-1"
						slot="content"
						style="
							border: 1px solid #ebe7e7;
							margin-top: 1rem;
							border-radius: 6px;
						"
					>
						<div
							*ngFor="let dish of loc.items; let i = index"
							class="flex f-align-center f-just-btw margin-top-1 width-100"
						>
							<div class="flex f-align-center width-100">
								<img
									[src]=" dish?.image
										"
									alt=""
									class="order-img"
								/>

								<div class="width-100">
									<ion-row class="width-100">
										<ion-col size="5" class="poppins-med"
											>{{ dish?.name }}</ion-col
										>
										<ion-col
											><span class="grey poppins"
												>qty:</span
											>
											&nbsp;
											<span class="poppins-bold"
												>{{ dish?.qty }}</span
											>
										</ion-col>
										<ion-col>
											<span class="red poppins-bold"
												>{{ dish?.vp?.price / 100 |
												currency }}</span
											></ion-col
										>
									</ion-row>
								</div>
							</div>
						</div>
					</section>

					<!---------------------------end sub cart-------------------->
				</section>
			</div>
		</section>
	</section>

	<!--------------->
	<!--------------->
	<!-- hc orders -->
	<!-------------->
	<!-------------->

	<div class="big-order-template" *ngIf="hcList && hcList.length > 0">
		<ion-row
			class="order-header"
			style="max-width: 1100px; margin: 1rem auto"
		>
			<ion-col size-lg="6" size-md="6" size-sm="12" size-xs="12">
				Order Placed
				<span class="f-small red poppins-bold bold-500"
					>{{calcDate(o?.createdAt?.seconds)}}</span
				>
			</ion-col>

			<ion-col size-lg="6" size-md="6" size-sm="12" size-xs="12">
				Order #
				<strong
					><span class="red poppins-bold bold-500"
						>{{o?.order?.orderId}}</span
					></strong
				>
			</ion-col>
		</ion-row>

		<div
			class="col-box padding-0-5"
			*ngFor="let p of hcList"
			style="
				max-width: 1100px;
				margin: 0.5rem auto;
				background-color: #ffffffcf !important;
			"
		>
			<section>
				<div class="my-sq-date poppins-med">
					Your Fulfilment Date:
					<span class="red poppins-bold">{{p.scheduledDate}}</span>
				</div>
			</section>

			<div style="min-width: 350px">
				<meal-list-component
					[edit]="'chooseMeal'"
					[meals]="p.items"
					[_showRemove]="false"
					[_isCart]="4"
				></meal-list-component>
			</div>
		</div>

		<div style="max-width: 1100px; margin: 1rem auto">
			<div class="poppins-bold">
				<h4>Shipping Address</h4>
			</div>
			<div style="padding-left: 1rem">
				<div>
					Name:{{o?.customer?.shipping.firstName + ' ' +
					o?.customer?.shipping.lastName}}
				</div>
				<div>{{o?.customer?.shipping.phone}}</div>
				<div>{{o?.customer?.shipping.email}}</div>
				<div>{{o?.customer?.shipping.address}}</div>
				<div>
					{{o?.customer?.shipping.city + ' ' +
					o?.customer?.shipping.state + ' ' +
					o?.customer?.shipping.zip}}
				</div>
			</div>
		</div>
	</div>

	<footer-component></footer-component>
	<app-nav-bottom></app-nav-bottom>
</ion-content>
