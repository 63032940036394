import { Component, Input, OnInit } from '@angular/core'
import { MealsService } from 'app/services/meals/meals.service'
import { UserProviderService } from 'app/services/userProvider/user-provider.service'
import { UtilService } from 'app/services/util/util.service'

@Component({
	selector: 'app-header-menu',
	templateUrl: './header-menu.component.html',
	styleUrls: ['./header-menu.component.scss'],
})
export class HeaderMenuComponent implements OnInit {
	@Input() mobile = false
	@Input() cart = true
	dropdownOpen = false;
	constructor(
		public readonly mealService: MealsService,
		public readonly userProvider: UserProviderService,
		public util: UtilService
	) {}

	ngOnInit() {
		
		console.log('mobile -=>', this.mobile)
		console.log("loggedin usere",this.userProvider?.loggedInUser)
	}

	myCart() {
		if (this.mealService.cartCount > 0) {
			this.mealService._menu = 'cart-list'
			this.util.navigateRoot('/cart-list')
		}
	}
	

	toggleDropdown() {
		console.log("toggle")
	  this.dropdownOpen = !this.dropdownOpen;
	}
	async ionViewWillEnter() {
		console.log("loggedin usere",this.userProvider?.loggedInUser)
	}
	
}
