import { Component, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { UserProviderService } from 'app/services/userProvider/user-provider.service'
import { UtilService } from 'app/services/util/util.service'
import { NavController } from '@ionic/angular'
import {
	CreatePaymentMethodData,
	StripeCardElementOptions,
	StripeElementsOptions,
} from '@stripe/stripe-js'
import { StripeCardComponent, StripeService } from 'ngx-stripe'
import { ApiService } from 'app/services/api/api.service'
import { MealsService } from 'app/services/meals/meals.service'

@Component({
	selector: 'app-add-card',
	templateUrl: './add-card.page.html',
	styleUrls: ['./add-card.page.scss'],
})
export class AddCardPage implements OnInit {
	stripeTest: FormGroup
	_done = false

	elementsOptions: StripeElementsOptions = { locale: 'en' }
	@ViewChild(StripeCardComponent, { static: true }) card: StripeCardComponent
	cardOptions: StripeCardElementOptions = {
		style: {
			base: {
				iconColor: '#666EE8',
				color: '#31325F',
				fontWeight: '300',
				fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
				fontSize: '16px',
				'::placeholder': {
					color: '#CFD7E0',
				},
			},
		},
	}
	constructor(
		public sce: UserProviderService,
		private stripeService: StripeService,
		private util: UtilService,
		private api: ApiService,
		private fb: FormBuilder,
		private navCtrl: NavController,
		public mealService: MealsService
	) {}

	ngOnInit() {
		if (
			!this.sce.loggedInUser.payMethod ||
			!this.sce.loggedInUser.payMethod.payment_method
		)
			this.sce.loggedInUser.payMethod = {}
		this.stripeTest = this.fb.group({
			name: [
				this.sce.loggedInUser.firstName +
					' ' +
					this.sce.loggedInUser.lastName,
				[Validators.required],
			],
		})

		console.log('user ->', this.sce.loggedInUser)
	}

	createPm(): void {
		const name = this.stripeTest.get('name').value
		console.log("name on card",name)
		if(name.trim() === "" ){
			this.util.showToast(
				'Card not saved, Please fill required fields and try again...'
			)
			return
		}
		const paymentMethodData: CreatePaymentMethodData = {
			type: 'card',
			card: this.card.element,
			billing_details: {
				email: this.sce.loggedInUser.email,
				name: name ? name : this.sce.loggedInUser.name,
				phone: this.sce.loggedInUser.phoneNumber
					? this.sce.loggedInUser.phoneNumber
					: '',
			},
		}
		this.util.showLoading('')
		this._done = true
		// console.log('paymentMethodData -->', paymentMethodData);

		this.stripeService.createPaymentMethod(paymentMethodData).subscribe(
			(result: any) => {
				this.util.hideLoading()
				if (result.error) {
					this.util.showToast(result.error.message)
					this._done = false
				} else if (result) {
					console.log(' createPm result -->', result)
					if (!this.sce.loggedInUser.payMethod)
						this.sce.loggedInUser.payMethod = {}
					const payMethod = result.paymentMethod
					const name = result.paymentMethod.billing_details.name
						? result.paymentMethod.billing_details.name
						: ''
					if (!this.sce.loggedInUser.stripeCustomerId)
						this.createCustomerId(payMethod, name)
					else
						this.checkCustomerId(
							this.sce.loggedInUser.stripeCustomerId,
							payMethod,
							name
						)
				}
			},
			(err) => {
				this.util.showToast(
					'Card not saved, please check and try again...'
				)
				this.util.hideLoading()
				this._done = false
			}
		)
	}

	createCustomerId(payMethod: any, nameOnCard: string) {
		let data = {
			description: nameOnCard,
			name: nameOnCard,
			email: this.sce.loggedInUser.email,
			phone: this.sce.loggedInUser.phoneNumber
				? this.sce.loggedInUser.phoneNumber
				: '',
		}
		// console.log('createCustomerId data -->', data);
		this.util.showLoading('')
		this.sce.loggedInUser.stripeCards = []
		this.sce.loggedInUser.stripeCustomerId = ''
		this.sce
			.createCustomer(data, 'https://api.stripe.com/v1/customers')
			.subscribe(
				(res) => {
					if (res.id) {
						this.util.hideLoading()
						this.sce.loggedInUser.stripeCustomerId = res.id
						this.attachPmToCustomer(payMethod, nameOnCard, res.id)
					}
				},
				(err) => {
					this.util.hideLoading()
					this._done = false
					this.util.showToast(
						'Card not saved, please check and try again...'
					)
				}
			)
	}

	checkCustomerId(cust_id: string, payMethod: any, nameOnCard: string) {
		this.util.showLoading('')
		this.sce
			.checkCustomer('https://api.stripe.com/v1/customers/' + cust_id)
			.subscribe(
				(res) => {
					this.util.hideLoading()
					// if customer exist
					if (res.id) {
						this.sce.loggedInUser.stripeCustomerId = res.id
						this.attachPmToCustomer(payMethod, nameOnCard, res.id)
					} else this.createCustomerId(payMethod, nameOnCard)
				},
				(err) => {
					this.util.hideLoading()
					this._done = false
					this.util.showToast(
						'Card not saved, please check try again...'
					)
					this.createCustomerId(payMethod, nameOnCard)
				}
			)
	}

	attachPmToCustomer(payMethod: any, name: string, customer?: string) {
		let paymentMethod = payMethod.id
		let data = {
			customer: customer,
		}
		this.util.showLoading('')
		this.sce
			.attachPmToCustomer(
				data,
				'https://api.stripe.com/v1/payment_methods/' +
					paymentMethod +
					'/attach'
			)
			.subscribe(
				(res) => {
					this.util.hideLoading()
					if (res.id) this.addCardFirbase(payMethod, name, customer)
				},
				(err) => {
					this.util.hideLoading()
					this.util.showToast(
						'Card not saved, please check and try again...'
					)
					this._done = false
				}
			)
	}

	addCardFirbase(payMethod: any, name: string, customer: string) {
		let data = {
			brand: payMethod.card.brand ? payMethod.card.brand : 'Card',
			last4: payMethod.card.last4,
			exp_month: payMethod.card.exp_month,
			exp_year: payMethod.card.exp_year,
			country: payMethod.card.country,
			name: name,
			type: payMethod.type,
			cardStatus: '',
			created: payMethod.created,
			payment_method: payMethod.id,
			customer: customer,
			default: false,
		}

		let found = -1
		if (!this.sce.loggedInUser.stripeCards) {
			this.sce.loggedInUser.stripeCards = []
			this.sce.loggedInUser.stripeCards.push(data)
		} else {
			this.sce.loggedInUser.stripeCards.forEach(
				(el: any, index: number) => {
					if (el.brand === data.brand && el.last4 === data.last4)
						found = index
				}
			)
			if (found > -1) this.sce.loggedInUser.stripeCards[found] = data
			else this.sce.loggedInUser.stripeCards.unshift(data)
		}
		if (this.sce.loggedInUser.stripeCards.length >= 1) {
			this.sce.loggedInUser.stripeCards.forEach((el) => {
				el.default = false
			})
			this.sce.loggedInUser.stripeCards[0].default = true
			this.sce.loggedInUser.payMethod =
				this.sce.loggedInUser.stripeCards[0]
		}
		console.log('Stripe Cards -> ', this.sce.loggedInUser.stripeCards)
		this.updateUser()

		if (this.sce._navFromCheckoutToBilling) {
			this.navCtrl.navigateForward('home/checkout', {
				skipLocationChange: true,
			})
		}
		// else {
		//     this.navCtrl.navigateBack('billing');
		// }
	}

	updateUser() {
		this.util.showLoading('')
		this.api
			.updateUser(this.sce.loggedInUser.id, this.sce.loggedInUser)
			.subscribe(
				async (updatedUser) => {
					this.util.hideLoading()
					this._done = false
					//this.mealService.dismiss()
				},
				(err) => {
					this.util.hideLoading()
					this._done = false
				}
			)
	}

	showToast(message: string) {
		const toast = this.util.presentToast(message)
	}
}
