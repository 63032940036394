<section class="show-small-header">
	<div class="nav-bot">
		<!----------------home-->
		<div
			(click)="ms._menu = 'dishes'; ms._searchClicked = false;  ms.resetSearch()"
			[routerLink]="['/dishes']"
		>
			<img
				[src]="
					'assets/icons/home' +
					(ms._menu == 'dishes' ? '2.svg' : '.svg')
				"
			/>
			<ion-label [ngClass]="ms._menu == 'dishes' ? 'sel' : ''"
				>Home</ion-label
			>
		</div>

		<div (click)="ms._menu = 'chefs'" [routerLink]="['/chefs']">
			<img
				[src]="
					'assets/icons/browse' +
					(ms._menu == 'chefs' ? '2.svg' : '.svg')
				"
			/>
			<ion-label [ngClass]="ms._menu == 'chefs' ? 'sel' : ''"
				>Chefs</ion-label
			>
		</div>

		<div (click)="ms._menu = 'browse'" [routerLink]="['/browse']">
			<img
				[src]="
					'assets/icons/browse' +
					(ms._menu == 'browse' ? '2.svg' : '.svg')
				"
			/>
			<ion-label [ngClass]="ms._menu == 'browse' ? 'sel' : ''"
				>Browse</ion-label
			>
		</div>

		<!----------------me-->

		<div (click)="ms._menu = 'profile'" [routerLink]="['/profile']">
			<img
				[src]="
					'assets/icons/me' + (ms._menu == 'profile' ? '2.svg' : '.svg')
				"
			/>
			<ion-label [ngClass]="ms._menu == 'profile' ? 'sel' : ''"
				>Me</ion-label
			>
		</div>

		<!----------------order-->

		<div (click)="ms._menu = 'orders'" [routerLink]="['/orders']">
			<img
				style="width: 22px"
				[src]="
					'assets/icons/order' +
					(ms._menu == 'orders' ? '2.svg' : '.svg')
				"
			/>
			<ion-label [ngClass]="ms._menu == 'orders' ? 'sel' : ''"
				>Orders</ion-label
			>
		</div>

		<!----------------cart-->

		<div>
			<section
				(click)="cart()"
				class="flex f-align-center f-col relative"
			>
				<div *ngIf="ms.cartCount > 0" class="cart-total-badge">
					{{ ms.cartCount }}
				</div>
				<img
					[src]="
					'assets/icons/cart' +
					(ms._menu == 'cart-list' ? '2.svg' : '.svg')
				"
				/>
				<ion-label
					[ngClass]="ms._menu == 'cart-list' ? 'sel' : ''"
					style="margin-top: 0 !important"
					>Cart</ion-label
				>
			</section>
		</div>
	</div>
</section>
