import { Component, Input, EventEmitter, OnInit, Output } from '@angular/core'
import { UserProviderService } from 'app/services/userProvider/user-provider.service'
import { UtilService } from 'app/services/util/util.service'
import { NavController } from '@ionic/angular'
import { MealsService } from 'app/services/meals/meals.service'

@Component({
	selector: 'app-billing',
	templateUrl: './billing.page.html',
	styleUrls: ['./billing.page.scss'],
})
export class BillingPage implements OnInit {
	@Input() _showHeader = true
	@Output() dism = new EventEmitter<any>()

	constructor(
		public sce: UserProviderService,
		private navCtrl: NavController,
		private util: UtilService,
		public mealService: MealsService
	) {}

	ngOnInit() {
		if (!this.sce.loggedInUser.id) {
			this.util.navigateRoot('auth')
			return
		}
		if (
			!this.sce.loggedInUser.payMethod ||
			!this.sce.loggedInUser.payMethod.payment_method
		)
			this.sce.loggedInUser.payMethod = {}
	}

	dismissme(evt: any) {
		console.log('1 dismissed...')
		this.dism.emit({})
	}

	goToAddCardPage() {
		if (this.sce._navFromCheckoutToBilling == true) {
			this.navCtrl.navigateForward('/billing/add-card', {
				skipLocationChange: true,
			})
		} else {
			this.navCtrl.navigateForward('billing/add-card')
		}
	}

	gotoProfile() {
		this.mealService.dismiss()
		this.navCtrl.navigateForward('/profile')
	}
}
