<ion-header class="header-wraper show-big-header">
	<div class="container-fluid border-bot flex f-align-center f-just-btw">
		<div class="glex f-align-center f-just-btw">
			<ion-buttons
				(click)="util.back()"
				*ngIf="
					userProvider._page != 'dishes' &&
					userProvider._page != 'history' &&
					userProvider._page != 'order-details' &&
					userProvider._page != 'orders' &&
					userProvider._page != 'checkout'
				"
			>
				<ion-button fill="clear">
					<img
						class="back-btn"
						src="assets/icons/left-arrow.svg"
						alt="Back Arrow"
					/>
				</ion-button>
			</ion-buttons>

			<div class="logoImage">
				<img src="assets/images/logo-web.png" alt="Logo" class="logo" />
			</div>
		</div>

		<div class="flex f-align-end flex-rowend">
			<app-header-menu> </app-header-menu>
		</div>
	</div>
	<header></header>
</ion-header>
