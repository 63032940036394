import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { IonicModule } from '@ionic/angular'
import { HeaderMenuComponent } from 'app/components/header-menu/header-menu.component'
import { HeaderNewMenuComponent } from 'app/components/header-new-menu/header-new-menu.component'
import { HeaderWebComponent } from 'app/components/header-web/header-web.component'
import { mobHeader } from 'app/components/mob-header/mob-header'
import { NavBottom } from 'app/components/nav-bottom/nav-bottom'

@NgModule({
	imports: [RouterModule, IonicModule, CommonModule, FormsModule],
	declarations: [
		HeaderMenuComponent,
		HeaderNewMenuComponent,
		HeaderWebComponent,

		NavBottom,
		mobHeader,
	],
	exports: [
		HeaderMenuComponent,
		HeaderNewMenuComponent,
		HeaderWebComponent,

		NavBottom,
		mobHeader,
	],
})
export class SharedModule {}
