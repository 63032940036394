<div class="dish border-bottom">
	<div class="dish-header">
		<div class="title-g-center">
			<h4>Dish Details</h4>
			<!-- hi details page -->
			<div><hr /></div>
		</div>
		<ion-icon
			(click)="mealService.dismiss()"
			class="cursor"
			name="close"
		></ion-icon>
	</div>
</div>

<ion-content #top>
	<div class="f-regular">
		<div class="dish">
			<img
				[src]="meal?.coverImage ? meal?.coverImage : 'assets/images/dishEmpty.jpg'"
				alt=""
			/>

			<div class="info f-regular">
				<ion-row>
					<ion-col
						class="flex-colcenter"
						sizeLg="4"
						sizeMd="4"
						sizeSm="12"
						sizeXs="12"
					>
						<div class="flex-row">
							<section *ngFor="let d of meal?.tagsList">
								<img
									*ngIf="d.selected"
									class="dietary"
									[src]="'assets/images/' + d.icon"
								/>
							</section>
						</div>
						<div
							class="flex-rowcenter red bold-600 margin-top-02 cursor"
							(click)="updateFavorite(meal)"
						>
							<ion-icon
								name="heart{{ getFavorite(meal?.id) ? '' : '-outline' }}"
							></ion-icon>
							&nbsp;
							<span class="bold-500"> Favorite</span>
							&nbsp;
						</div>
					</ion-col>

					<ion-col
						class="flex-colcenter"
						sizeLg="4"
						sizeMd="4"
						sizeSm="12"
						sizeXs="12"
					>
						<div style="width: 450px margin-top-0-5" class="center">
							<h4>{{meal?.name}}</h4>
							<app-rating
								*ngIf="meal?.review"
								[review]="meal?.review"
								[color]="'#00b518'"
							></app-rating>

							<!-- <div
								class="flex-rowcenter orange-dark"
								style="margin: 0.5rem auto"
							>
								<div style="padding: 0.1rem 0.5rem 0.35rem 0">
									By {{ meal?.vendor?.restoName }}
								</div>
								<app-rating
									*ngIf="meal?.vendor?.review"
									[review]="meal?.vendor?.review"
									[color]="'#ff6b52'"
								></app-rating>
							</div> -->
							<div
								class="flex-columncenter orange-dark"
								style="margin: 0.5rem auto"
								>
								<div style="padding: 0.1rem 0.5rem 0.35rem 0; text-align: center;">
									By {{ meal?.vendor?.restoName }}
								</div>
								<app-rating
									*ngIf="meal?.vendor?.review"
									[review]="meal?.vendor?.review"
									[color]="'#ff6b52'"
								></app-rating>
							</div>

						</div>

						<div
							class="spice-lev-2"
							*ngIf="mealService.mySpice(meal?.spiceLevel) > -1"
						>
							Spice Level
							<input
								name="spice"
								type="range"
								min="0"
								max="5"
								[value]="meal?.spiceLevel"
								class="slider"
								(change)="change($event, meal)"
							/>

							<img
								style="height: 30px; width: 12px"
								src="assets/images/chil.png"
							/>&nbsp;{{ meal?.spiceLevel }}/5
						</div>
						<section class="center">
							<!---------------------->

							<div
								id=" margin-top-1"
								*ngIf="mealService.findHCtotal(meal) > 0"
							>
								<button
									class="c-btn"
									(click)="mealService.removeHCMeal(meal)"
								>
									-
								</button>
								<button class="c-btn">
									{{ mealService.findHCtotal(meal) }}
								</button>
								<button
									class="c-btn"
									(click)="mealService.addHCMeal(meal)"
								>
									+
								</button>
							</div>
							<div *ngIf="mealService.findHCtotal(meal) <= 0">
								<button
									class="c-btn"
									(click)="mealService.addHCMeal(meal)"
								>
									Add to Cart
								</button>
							</div>
							<!---------------------->
						</section>
					</ion-col>
					<ion-col
						class="center"
						sizeLg="4"
						sizeMd="4"
						sizeSm="12"
						sizeXs="12"
					>
						<ion-button class="orange-btn" (click)="addReview()"
							>Add Review</ion-button
						>
					</ion-col>
				</ion-row>
			</div>
		</div>

		<div class="max-width-1200 marg-1">
			<!----------------------create a review ----------------->
			<div
				class="col-box"
				style="margin: 0 auto 3rem auto"
				*ngIf="mealService._showAddReview"
			>
				<div class="h center padding-1">
					Create a Review
					<div class="line"></div>
				</div>

				<app-dish-review
					[meal]="meal"
					[review]="review"
				></app-dish-review>
			</div>

			<!----------------------description----------------->

			<div class="col-box padding-2" style="margin: 0 auto">
				<div class="title-g">
					<h4>Description</h4>
					<div><hr /></div>
				</div>

				<div class="margin-top-1">{{meal?.description}}</div>
			</div>

			<!--------------------------nutrition---------------------->

			<div class="col-box padding-2" style="margin: 1rem auto">
				<div class="title-g">
					<h4>Nutrition Values</h4>
					<div><hr /></div>
				</div>

				<div id="meal" class="center">
					<div id="nutrition" class="section-header">
						<div id="nutrition-featured-list">
							<div class="nutrition-featured-item">
								<div class="nutrition-featured-container">
									<p class="value">
										{{meal?.nutrition?.cals?meal?.nutrition?.cals
										: 0}}
									</p>
									<p class="nutitle">kcals</p>
								</div>
							</div>
							<div class="nutrition-featured-item">
								<div class="nutrition-featured-container">
									<p class="value">
										{{meal?.nutrition?.carbs ?
										meal?.nutrition?.carbs : 0}} g
									</p>
									<p class="nutitle">Carbs</p>
								</div>
							</div>

							<div class="nutrition-featured-item">
								<div class="nutrition-featured-container">
									<p class="value">
										{{meal?.nutrition?.protein}} g
									</p>
									<p class="nutitle">Protein</p>
								</div>
							</div>

							<div class="nutrition-featured-item">
								<div class="nutrition-featured-container">
									<p class="value">
										{{meal?.nutrition?.fat ?
										meal?.nutrition?.fat : 0}} g
									</p>
									<p class="nutitle">Fat</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!--------------------ingredient--------->

			<div class="col-box padding-2" style="margin: 0 auto">
				<div class="title-g">
					<h4>Ingredients</h4>
					<div><hr /></div>
				</div>

				<div class="margin-top-1">
					<span
						*ngFor="let ingredient of meal?.ingredients; let k=index"
					>
						{{ getIngredient(ingredient) }}{{ k ==
						meal?.ingredients.length-1 ? '' : ','}}
					</span>
				</div>
			</div>

			<!--------------------allergy--------->

			<div class="col-box padding-2" style="margin: 2rem auto">
				<div class="title-g">
					<h4>Allergy Info</h4>
					<div><hr /></div>
				</div>

				<div class="margin-top-1">
					Some of our tasty dishes contain gluten, dairy, nuts, and
					shellfish. Enjoy!
				</div>
			</div>

			<!------------------------reviews------------------->
			<div class="col-box padding-2">
				<app-rev-dish
					*ngIf="!mealService._showAddReview"
					class="margin-top-2"
					[meal]="meal"
					[_all]="_all"
				></app-rev-dish>
			</div>
		</div>
	</div>

	<footer-component></footer-component>
</ion-content>
