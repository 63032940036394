import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ModalController, NavController } from '@ionic/angular'
import { BehaviorSubject, combineLatest, debounceTime } from 'rxjs'
import { ChefPage } from 'app/pages/about-chef/chef.page'
import { AddCardPage } from 'app/pages/billing/add-card/add-card.page'
import { BillingPage } from 'app/pages/billing/billing.page'
import { EditPage } from 'app/pages/edit/edit.page'
import { HistoryPage } from 'app/pages/history/history.page'
import { ResetPage } from 'app/pages/reset/reset.page'
import { environment } from 'environments/environment'
import { ApiService } from '../api/api.service'
import { UserProviderService } from '../userProvider/user-provider.service'
import { UtilService } from '../util/util.service'
import { DatePipe } from '@angular/common'
import * as _ from 'lodash'
import { PopMealPage } from 'app/pages/meal-pop/meal-pop.page'
import * as moment from 'moment'
import { Router } from '@angular/router'

@Injectable({
	providedIn: 'root',
})
export class MealsService {
	selectedCategoryId = ''
	basePremium = 8
	userZip = ''
	radius = ''
	updateUserData = { page: '', action: '' }
	instructions = ''
	orderId
	_showAlertBar = false
	_showMobile = false
	_selectedOrder
	_isNearBy = false
	_showZipSearchForm: boolean
	data: any = {}
	westCost = ['CA', 'NV', 'AZ, OR', 'ID', 'UT', 'CO', 'WA']
	selectedVendor: any = {}
	selectedVendorId
	selectedVendorIndex = 0
	selectedMeal
	_invalidZip = false
	userHub: any
	_chefPopup = false
	_mealPopup = false
	selectedHub = ''
	selectedZone = ''
	_changeZip = ''
	_showModal1 = false
	fedexToken = ''
	filters: any
	tagsList: any[] = []
	guestRegistered = false
	selectedChef: any = {}
	chosenDate
	chosenOrderDate
	chosenDeliveryDate
	totals: any = {}
	_showAddReview = false
	currentDate
	currentYear = new Date().getFullYear()
	calendarArr = []
	schDay = ''
	fi: any[] = []
	mList: any[] = []
	_showDishesFilters = false
	_showGroceryFilters = false
	selectedItemsPerPage: any = 10
	selectedOrderBy: any = 5
	selectedPage = 1
	pagination = 10
	selectedCategoryTotalPages
	searchStr = ''
	breadCrumb: any[] = []
	menuLevel = -1
	query = ''
	_searchClicked = false
	_showSearchResults = false
	originalData = {}
	itemId = ''
	_showMoreDishes = false
	device = ''
	dataLoaded = false
	loadingDataErr = ''
	orderMode = 'PICKUP'
	searchloc: any[] = []
	_spin = false
	_menu = 'dishes'
	dishQuery = ''
	_showDishesCalendar = false
	hcSqorderId = ''
	lastOrder = []
	reGrMeals: any[] = []
	order: any = {}
	carts: any = []

	tips = 0
	ct = [
		{
			t: false,
			val: 15,
		},
		{
			t: true,
			val: 18,
		},
		{
			t: false,
			val: 20,
		},
		{
			t: false,
			val: 22,
		},
		{
			t: false,
			val: 0,
		},
	]
	_otherTips = false
	selectedDay = ''
	constructor(
		private api: ApiService,
		private userProvider: UserProviderService,
		private navCtrl: NavController,
		private modalController: ModalController,
		private util: UtilService,
		private http: HttpClient,
		public datePipe: DatePipe,
		private router: Router
	) {
		this.initAllData()
	}

	/**------------------------------------------------------------
	 *
	 * @param myZip
	 * @returns
	 */
	async loadData(myZip?: string): Promise<any> {
		return new Promise<any>(async (resolve: any, reject: any) => {
			try {
				if (!myZip) {
					const zip = await this.api.getZipCode() // '92612' //
					console.log('get zip --==>', zip)
					this.userZip = zip ? zip : ''
					await this.getAllMealData()
					resolve(true)
				} else {
					this.userZip = myZip ? myZip : ''
					await this.getAllMealData()
					resolve(true)
				}
			} catch (e) {
				reject(e)
				console.log('load data err->', e)
			}
		})
	}

	/*
	 * @returns get all data (cat, meals, vendors, ingredients, zone)
	 */
	async getAllMealData() {
		return new Promise<any>((resolved, rejected) => {
			return (
				combineLatest([
					this.api.getActiveList('categories'),
					this.api.getActiveList('meals'),
					this.api.getList('ingredients'),
					this.api.getZoneList('zone'),
					// this.api.getActiveVendorsList('vendors'),
				]).subscribe(async (res: any) => {
					this.data.categories = res[0]
					this.data.meals = res[1]
					this.data.ingredients = res[2]
					this.data.zone = res[3]
					// this.data.vendors = res[4]

					this.util.showLoading2('')
					try {
						const dt = {
							zipcode: this.userZip,
							radius: environment.radius,
							dietery: [],
							categories: [],
							pageSize: 50,
						}

						console.log('1- API call-==>', dt)
						const myVendors: any =
							await this.api.getActiveVendorsListByZip(dt)
						console.log('2- API call myVendors-==>', myVendors)
						this.util.hideLoading2()

						// square vendors
						this.data.sqVendors =
							myVendors &&
							myVendors.squareLocations &&
							myVendors.squareLocations.length > 0
								? myVendors.squareLocations
								: []

						this.data.vendors =
							myVendors &&
							myVendors.homeChef &&
							myVendors.homeChef.length > 0
								? myVendors.homeChef
								: []
						this.assembleSquareData()
						await this.initCarts()
						this.findZoneHub(this.userZip, true)
						if (
							this.data.vendors.length === 0 &&
							this.data.sqVendors.length === 0
						) {
							this.loadingDataErr = 'Invalid'
						}
						console.log('55 - nearBy data . . . ', this.data)
						resolved(true)
					} catch (e) {
						this.util.hideLoading2()
						console.log('55 - get vendors By Zip err', e)
						this.initAllData()
						this.loadingDataErr = 'Invalid'
					}
				}),
				(err: any) => {
					rejected(err)
					console.log('loading all meal data ->', err)
				}
			)
		})
	}

	/**-----------------------------------------------
	 *
	 * @param _isChef
	 */

	async initCarts() {
		if (this.userProvider.loggedInUser.id) {
			let userZip = await this.util.convertAddressToLatLng(
				this.userProvider.loggedInUser.previousZip
			)
			let previouszip = await await this.util.convertAddressToLatLng(
				this.userZip
			)
			let distance = await this.util.getDistanceBtw2points(
				userZip,
				previouszip
			)
			distance = (distance * 0.621371) / 1000
			console.log('distance', distance)
			let nearBy = distance < 10 ? true : false

			if (nearBy) {
				const sqCart = this.userProvider.loggedInUser?.sqCart
				this.data.sqOrders.orders =
					sqCart && Object.keys(sqCart).length > 0 ? sqCart : []

				const hcCart = this.userProvider.loggedInUser?.hcCart
				this.data.selectedPlan =
					hcCart && Object.keys(hcCart).length > 0
						? hcCart
						: { meals: [], orders: [] }
			}
		}
		console.log(this.data.selectedPlan)
	}
	assembleSquareData() {
		//find sqRestaurants & sqFoodTrucks
		let restaurants: any[] = []
		let foodTrucks: any[] = []
		let tangyRestro: any[] = []

		this.data.sqVendors.forEach((v: any, vendorIndex: number) => {
			if (v.squareCompleted && v.square && v.square.length > 0) {
				v.square.forEach((sq: any, accountIndex: number) => {
					const addData = {
						discount: 2,
						rating: 4.0,
						reviews: '2.1k',
						vendorIndex,
						accountIndex,
					}
					const resto = sq.sqLocations
						.filter((loc: any) => loc.type === 'PHYSICAL')
						.map((loc: any) => ({ ...loc, ...addData }))

					if (resto && resto.length > 0) restaurants.push(...resto)

					const trucks = sq.sqLocations
						.filter((loc: any) => loc.type === 'MOBILE')
						.map((loc: any) => ({ ...loc, ...addData }))

					if (trucks && trucks.length > 0) foodTrucks.push(...trucks)

					const tangyloc = sq.sqLocations
						.filter((loc: any) => loc.type === 'MOBILE')
						.map((loc: any) => ({ ...loc, ...addData }))
						.filter((truck: any) => truck.id == 'LF3XGFR5Z16GX')

					if (tangyloc && tangyloc.length > 0)
						tangyRestro.push(...tangyloc)
				})
			}
		})

		this.data.sqFoodTrucks = foodTrucks
		this.data.sqRestaurants = restaurants
		this.data.tangyRestro = tangyRestro
		console.log('11')

		// -------------------------------
		//find square categories & dietary from FB db

		let sqAllItems = []
		this.data.sqVendors.forEach((el1: any) => {
			el1.square.forEach((el: any) => {
				const items = el.sqItems.forEach((myItem: any) => {
					if (myItem.type === 'ITEM') sqAllItems.push(myItem)
				})
			})
		})

		// cat - resto
		this.data.sqRestaurants.forEach((el: any, index: number) => {
			el.itemsData.forEach((item: any) => {
				const f = sqAllItems.find((sqItem) => item.id === sqItem.id)
				item.categories =
					f && f.categories && f.categories.length > 0
						? f.categories
						: []
				item.dietary =
					f && f.dietary && f.dietary.length > 0 ? f.dietary : []
				// if (f && f.categories && f.categories.length > 0)
				// 	console.log(index, f.id, 'Resto - item with cat ->', item)
			})
		})

		// cat - trucks
		this.data.sqFoodTrucks.forEach((el: any, index: number) => {
			el.itemsData.forEach((item: any) => {
				const f = sqAllItems.find((sqItem) => item.id === sqItem.id)
				item.categories =
					f && f.categories && f.categories.length > 0
						? f.categories
						: []
				item.dietary =
					f && f.dietary && f.dietary.length > 0 ? f.dietary : []
				// if (f && f.categories && f.categories.length > 0)
				// 	console.log(index, f.id, 'Truck - item with cat ->', item)
			})
		})

		//------------------------------------------
		// fetch FB-Square categories, combine them with assembled hf cat

		let myCats = []
		this.data.sqRestaurants.forEach((el: any, index: number) => {
			el.itemsData.forEach((item: any) => {
				item.categories.forEach((cat: any) => {
					// fetch cat in orginal catlist
					const idx = this.data.categories.findIndex(
						(c: any) => c.id === cat.id
					)
					const exist = myCats.findIndex(
						(myCat) => myCat.id === cat.id
					)
					if (idx >= 0 && exist < 0)
						myCats.push(this.data.categories[idx])
				})
			})
		})

		this.data.sqFoodTrucks.forEach((el: any, index: number) => {
			el.itemsData.forEach((item: any) => {
				item.categories.forEach((cat: any) => {
					// fetch cat in orginal catlist
					const idx = this.data.categories.findIndex(
						(c: any) => c.id === cat.id
					)
					const exist = myCats.findIndex(
						(myCat) => myCat.id === cat.id
					)
					if (idx >= 0 && exist < 0)
						myCats.push(this.data.categories[idx])
				})
			})
		})

		this.data.assembledCategories = myCats
		console.log('22 assembled SQ cats # -->', this.data.assembledCategories)

		//--------------------------------------------------------

		this.searchStr = ''
		this.searchDishesItems()
		console.log('33 -- filter hc/resto/truck by searchStr & selected cat')
	}

	assembleChefMealData(st: string, _isChef?: boolean) {
		console.log('=> assemble Meal Data called', st)
		this.data.assembledvendors = [] //this.data.vendors
		this.data.assembledMeals = []
		const chefCats = []

		// filter vendors by zone
		this.data.vendors.forEach((el: any) => {
			if (this._isNearBy) {
				let hubPushed = false
				const vendorHub = el.zoneHub && el.zoneHub.split('|')[1]
				this.userHub.hubs.forEach((hub) => {
					if (
						(el.zone == this.userHub.zone && vendorHub) ==
							hub.num &&
						!hubPushed &&
						hub.nearBy
					) {
						this.data.assembledvendors.push(el)
						hubPushed = true
					}
				})
			} else if (el.zone == this.userHub.zone) {
				this.data.assembledvendors.push(el)
			}
		})

		// retrieve vendor & ingredients details for each  meal
		this.data.meals.forEach((meal: any, index) => {
			const foundVendor = this.data.assembledvendors.find(
				(v) => v.id === meal.vendorId
			)

			if (foundVendor && foundVendor.id) {
				meal.vendor = foundVendor

				// filter cat ---> assembled Categories
				this.data.categories.forEach((cat) => {
					let mealHasCat = false
					meal.parentMealList.forEach((parent) => {
						if (parent.includes(cat.id)) mealHasCat = true
					})
					if (mealHasCat) {
						const idx = chefCats.findIndex((el) => el.id === cat.id)
						if (idx < 0) chefCats.push(cat)
					}
				})

				// cat union (square & HC)
				this.data.assembledCategories.forEach((ac: any) => {
					const idx = chefCats.findIndex(
						(myCat) => myCat.id === ac.id
					)
					if (idx < 0) chefCats.push(ac)
				})

				this.data.assembledCategories = chefCats.sort((a, b) =>
					a.name < b.name ? -1 : 1
				)

				// retrieve ingredients for each meal
				meal.ingredients.forEach((ingredient, index) => {
					const foundIngredient = this.data.ingredients.find(
						(fullIngredient) => fullIngredient.id === ingredient
					)
					if (foundIngredient) {
						meal.ingredients[index] = {
							name: foundIngredient.name,
							image: foundIngredient.image,
							id: foundIngredient.id,
						}
					}
				})

				// save selected meals --> assembled Meals for all cat
				this.data.assembledMeals.push(meal)
			}
		})

		this.searchDishesItems()

		// refresh calendar
		this.refreshCalendarList()
	}

	/**-----------------------------------------------------------------
	 *
	 * @param searchStr
	 * * combine with cat filter
	 */

	getLatestTen(data) {
		let latestTen = []
		if (data && data.length > 0) latestTen = data.slice(0, 9)
		return latestTen
	}

	async searchDishesItems() {
		const st = this.searchStr.toLowerCase()
		const myMeals = _.cloneDeep(this.data.assembledMeals)
		const myResto = _.cloneDeep(this.data.sqRestaurants)
		const myTrucks = _.cloneDeep(this.data.sqFoodTrucks)
		const myTangyRestro = _.cloneDeep(this.data.tangyRestro)

		let l1 = []
		let l2 = []
		let l3 = []
		let l4 = []

		// hf
		myMeals.forEach((el: any) => {
			const n = (
				el.vendor.firstName +
				' ' +
				el.vendor.lastName
			).toLowerCase()
			if (
				el.name.toLowerCase().indexOf(st) >= 0 ||
				n.indexOf(st) >= 0 ||
				!st
			) {
				const idx = l1.findIndex(
					(vend) => vend.vendorId === el.vendorId
				)
				if (idx < 0) l1.push(el)
			}
		})

		// resto
		myResto.forEach((el: any) => {
			let itemsList = []
			el.itemsData.forEach((item: any) => {
				if (item.name.toLowerCase().indexOf(st) >= 0 || !st)
					itemsList.push(item)
			})

			// check if resto name match
			if (el.name.toLowerCase().indexOf(st) >= 0 || !st) {
				if (itemsList.length === 0) l2.push(el)
				else {
					el.itemsData = itemsList
					l2.push(el)
				}
			} else {
				if (itemsList.length > 0) {
					el.itemsData = itemsList
					l2.push(el)
				}
			}
		})

		// trucks
		myTrucks.forEach((el: any) => {
			let itemsList = []
			el.itemsData.forEach((item: any) => {
				if (item.name.toLowerCase().indexOf(st) >= 0 || !st)
					itemsList.push(item)
			})

			// check if resto name match
			if (el.name.toLowerCase().indexOf(st) >= 0 || !st) {
				if (itemsList.length === 0) l3.push(el)
				else {
					el.itemsData = itemsList
					l3.push(el)
				}
			} else {
				if (itemsList.length > 0) {
					el.itemsData = itemsList
					l3.push(el)
				}
			}
		})

		// save search string in db
		let usl
		if (
			this.userProvider.loggedInUser.searchList &&
			this.userProvider.loggedInUser.searchList.length > 0
		)
			usl = this.getLatestTen(this.userProvider.loggedInUser.searchList)

		if (st && (l1.length > 0 || l2.length > 0 || l3.length > 0)) {
			if (usl && usl.length > 0) {
				const l = usl.filter((el: any) => el.str.toLowerCase() === st)

				if (l && l.length === 0) {
					usl.unshift({
						date: new Date().toISOString(),
						str: st,
					})
					this.updateRec(usl)
				}
			} else {
				usl = [
					{
						date: new Date().toISOString(),
						str: st,
					},
				]
				this.updateRec(usl)
			}
		}

		this._showSearchResults = true
		this.filterByCAtegory(l1, l2, l3)
	}

	/**-----------------------------------------------------------
	 *
	 */
	filterByCAtegory(l1: any, l2: any, l3: any) {
		let arr: any
		if (this.selectedCategoryId) {
			//Home chef
			arr = []
			l1.forEach((dish: any) => {
				if (dish.vendor.cuisine.length > 0) {
					dish.vendor.cuisine.forEach((cat) => {
						if (this.selectedCategoryId == cat?.id) {
							arr.push(dish)
						}
					})
				}
				dish.parentMealList.forEach((cat) => {
					if (cat == this.selectedCategoryId) {
						arr.push(dish)
					}
				})
			})
			this.data.filteredMeals = arr

			// sq restaurant
			arr = []
			l2.forEach((el: any) => {
				const items1 = []
				el.itemsData.forEach((item: any) => {
					const idx = item.categories.findIndex(
						(cat: any) => cat.id === this.selectedCategoryId
					)
					if (idx >= 0) items1.push(item)
				})
				if (items1.length > 0) {
					el.itemsData = items1
					arr.push(el)
				}
			})
			this.data.filteredResto = arr

			// sq trucks
			arr = []
			l3.forEach((el: any) => {
				const items2 = []
				el.itemsData.forEach((item: any) => {
					const idx = item.categories.findIndex(
						(cat: any) => cat.id === this.selectedCategoryId
					)
					if (idx >= 0) items2.push(item)
				})
				if (items2.length > 0) {
					el.itemsData = items2
					arr.push(el)
				}
			})
			this.data.filteredTruck = arr
			this.data.tangyRestro =
				arr.filter((truck: any) => truck.id === 'LF3XGFR5Z16GX') || []
		} else {
			this.data.filteredMeals = l1
			this.data.filteredResto = l2
			this.data.filteredTruck = l3
			// this.data.tangyRestro =  l4
		}
	}

	/**------------------------------------------------------------
	 *
	 * @param evt
	 */
	async checkZipInput(evt: any) {
		console.log('=> check splash zip called')
		this.loadingDataErr = ''
		this.data.filteredMeals = []
		this.userZip = evt.target && evt.target.value ? evt.target.value : ''

		if (this.userZip.length === 5) {
			this.util.showLoading('')
			await this.loadData(this.userZip)
			this.util.handleError
		}
	}

	async checkZipMyInput() {
		this.loadingDataErr = ''
		this.data.filteredMeals = []
		if (this.userZip.length === 5) {
			this.util.showLoading('')
			await this.loadData(this.userZip)
			this.util.handleError
		}
	}

	/**-------------------------------------------------------------
	 *
	 */
	refreshCalendarList() {
		let startDate = 4
		let newDate = new Date()

		if (this._isNearBy) {
			const time = this.datePipe.transform(newDate, 'hh:mm a')
			if (time.toLocaleLowerCase().indexOf('am') >= 0) startDate = 0
			else startDate = 1
		}
		newDate.setDate(newDate.getDate() + startDate)
		this.currentDate = this.datePipe.transform(newDate, 'EEE, MMM d, y')
		console.log('added days -------->', startDate, this.currentDate)
		this.calendarArr = []
		let dt
		let dName
		let dNum
		for (let day = 0; day < 20; day++) {
			newDate.setDate(newDate.getDate() + 1)
			dt = this.datePipe.transform(newDate, 'EEE, MMM d, y')
			dName = this.datePipe.transform(newDate, 'EEE')
			dNum = this.datePipe.transform(newDate, 'MMM d')
			if (dt.indexOf('Sun') < 0)
				this.calendarArr.push({ dName, dNum, dt })
		}

		this.chosenDate = this.calendarArr[0].dt
		this.filters.deliveryDay = this.calendarArr[0].dName.substring(0, 2)
		console.log('Chosen Date -=-=-=-=--=>', this.chosenDate)
	}

	async openModal(component: any) {
		const modal = await this.modalController.create({
			component:
				component === 'HistoryPage'
					? HistoryPage
					: component === 'EditPage'
					? EditPage
					: component === 'BillingPage'
					? BillingPage
					: component === 'AddCardPage'
					? AddCardPage
					: component === 'ResetPage'
					? ResetPage
					: component === 'PopMealPage'
					? PopMealPage
					: component === 'ChefPage'
					? ChefPage
					: '',
			componentProps: {},
			cssClass:
				component === 'ResetPage'
					? 'my-modal-class-2'
					: 'my-modal-class',
		})
		return await modal.present()
	}

	dismiss() {
		console.log('dismissed')
		this.modalController.dismiss({
			dismissed: true,
		})
	}

	// sort by date asc
	sortArray(a, b) {
		return (
			new Date(a.scheduledDate).getTime() -
			new Date(b.scheduledDate).getTime()
		)
	}

	// sort by date asc
	sortArrayDesc(a, b) {
		return (
			new Date(b.scheduledDate).getTime() -
			new Date(a.scheduledDate).getTime()
		)
	}

	viewDish(meal: any) {
		const name = meal.name.replace(
			/\#|\.|\,|\&|\#|\*|\?|\}|\{|\]|\[|\(|\)|%/gi,
			''
		)
		const url = 'meal/' + name + '/' + meal.id
		console.log('url ->', url)
		this.util.navigateForward(url)
	}

	viewMeal(meal: any) {
		this.selectedMeal = meal
		this._mealPopup = true
		this.openModal('PopMealPage')
	}

	viewOrder(id: string) {
		const url = 'order-details/' + id + '/all'
		console.log('order url ->', url)
		this.util.navigateForward(url)
	}

	filterMenu() {
		this.fi = []
		let dish: any
		console.log(
			'Theme - selected vendor ->',
			this.chosenDate,
			this.selectedVendor
		)
		if (
			this.selectedVendor.menuTheme &&
			this.selectedVendor.menuTheme.activeMenu &&
			this.selectedVendor.menuTheme.activeMenu.length > 0
		) {
			this.selectedVendor.menuTheme.activeMenu.forEach((el: any) => {
				//check if active menu are in the same zone

				if (
					this.data?.assembledMeals &&
					this.data?.assembledMeals.length > 0
				) {
					dish = {}
					const index = this.data.assembledMeals.findIndex(
						(recentMeal: any) => el.id === recentMeal.id
					)
					if (index >= 0) {
						dish = this.data.assembledMeals[index]
						const idx = dish.availability.findIndex(
							(a: any) =>
								a.day === this.filters.deliveryDay && a.selected
						)
						dish.showChefMenu = idx >= 0 ? true : false
					} else if (!el.type) {
						dish = el
						dish.showChefMenu = false
					}

					if (el.type === 'cat') this.fi.push(el)
					else {
						if (dish.showChefMenu) this.fi.push(dish)
					}
				} else this.util.navigateRoot('dishes')
			})
		}

		if (this.fi.length > 0) {
			let total = 0
			for (let i = this.fi.length - 1; i >= 0; i--) {
				if (this.fi[i].type === 'cat') {
					this.fi[i].total = total
					total = 0
				} else ++total
			}
		}

		console.log('fi >>>>>>>>>>>>>>>>-=-=>', this.fi)
		return this.fi
	}

	private findStateFromZip(zipcode): string {
		let st
		let state
		if (zipcode >= 35000 && zipcode <= 36999) {
			st = 'AL'
			state = 'Alabama'
		} else if (zipcode >= 99500 && zipcode <= 99999) {
			st = 'AK'
			state = 'Alaska'
		} else if (zipcode >= 85000 && zipcode <= 86999) {
			st = 'AZ'
			state = 'Arizona'
		} else if (zipcode >= 71600 && zipcode <= 72999) {
			st = 'AR'
			state = 'Arkansas'
		} else if (zipcode >= 90000 && zipcode <= 96699) {
			st = 'CA'
			state = 'California'
		} else if (zipcode >= 80000 && zipcode <= 81999) {
			st = 'CO'
			state = 'Colorado'
		} else if (
			(zipcode >= 6000 && zipcode <= 6389) ||
			(zipcode >= 6391 && zipcode <= 6999)
		) {
			st = 'CT'
			state = 'Connecticut'
		} else if (zipcode >= 19700 && zipcode <= 19999) {
			st = 'DE'
			state = 'Delaware'
		} else if (zipcode >= 32000 && zipcode <= 34999) {
			st = 'FL'
			state = 'Florida'
		} else if (
			(zipcode >= 30000 && zipcode <= 31999) ||
			(zipcode >= 39800 && zipcode <= 39999)
		) {
			st = 'GA'
			state = 'Georgia'
		} else if (zipcode >= 96700 && zipcode <= 96999) {
			st = 'HI'
			state = 'Hawaii'
		} else if (zipcode >= 83200 && zipcode <= 83999) {
			st = 'ID'
			state = 'Idaho'
		} else if (zipcode >= 60000 && zipcode <= 62999) {
			st = 'IL'
			state = 'Illinois'
		} else if (zipcode >= 46000 && zipcode <= 47999) {
			st = 'IN'
			state = 'Indiana'
		} else if (zipcode >= 50000 && zipcode <= 52999) {
			st = 'IA'
			state = 'Iowa'
		} else if (zipcode >= 66000 && zipcode <= 67999) {
			st = 'KS'
			state = 'Kansas'
		} else if (zipcode >= 40000 && zipcode <= 42999) {
			st = 'KY'
			state = 'Kentucky'
		} else if (zipcode >= 70000 && zipcode <= 71599) {
			st = 'LA'
			state = 'Louisiana'
		} else if (zipcode >= 3900 && zipcode <= 4999) {
			st = 'ME'
			state = 'Maine'
		} else if (zipcode >= 20600 && zipcode <= 21999) {
			st = 'MD'
			state = 'Maryland'
		} else if (
			(zipcode >= 1000 && zipcode <= 2799) ||
			zipcode == 5501 ||
			zipcode == 5544
		) {
			st = 'MA'
			state = 'Massachusetts'
		} else if (zipcode >= 48000 && zipcode <= 49999) {
			st = 'MI'
			state = 'Michigan'
		} else if (zipcode >= 55000 && zipcode <= 56899) {
			st = 'MN'
			state = 'Minnesota'
		} else if (zipcode >= 38600 && zipcode <= 39999) {
			st = 'MS'
			state = 'Mississippi'
		} else if (zipcode >= 63000 && zipcode <= 65999) {
			st = 'MO'
			state = 'Missouri'
		} else if (zipcode >= 59000 && zipcode <= 59999) {
			st = 'MT'
			state = 'Montana'
		} else if (zipcode >= 27000 && zipcode <= 28999) {
			st = 'NC'
			state = 'North Carolina'
		} else if (zipcode >= 58000 && zipcode <= 58999) {
			st = 'ND'
			state = 'North Dakota'
		} else if (zipcode >= 68000 && zipcode <= 69999) {
			st = 'NE'
			state = 'Nebraska'
		} else if (zipcode >= 88900 && zipcode <= 89999) {
			st = 'NV'
			state = 'Nevada'
		} else if (zipcode >= 3000 && zipcode <= 3899) {
			st = 'NH'
			state = 'New Hampshire'
		} else if (zipcode >= 7000 && zipcode <= 8999) {
			st = 'NJ'
			state = 'New Jersey'
		} else if (zipcode >= 87000 && zipcode <= 88499) {
			st = 'NM'
			state = 'New Mexico'
		} else if (
			(zipcode >= 10000 && zipcode <= 14999) ||
			zipcode == 6390 ||
			zipcode == 501 ||
			zipcode == 544
		) {
			st = 'NY'
			state = 'New York'
		} else if (zipcode >= 43000 && zipcode <= 45999) {
			st = 'OH'
			state = 'Ohio'
		} else if (
			(zipcode >= 73000 && zipcode <= 73199) ||
			(zipcode >= 73400 && zipcode <= 74999)
		) {
			st = 'OK'
			state = 'Oklahoma'
		} else if (zipcode >= 97000 && zipcode <= 97999) {
			st = 'OR'
			state = 'Oregon'
		} else if (zipcode >= 15000 && zipcode <= 19699) {
			st = 'PA'
			state = 'Pennsylvania'
		} else if (zipcode >= 300 && zipcode <= 999) {
			st = 'PR'
			state = 'Puerto Rico'
		} else if (zipcode >= 2800 && zipcode <= 2999) {
			st = 'RI'
			state = 'Rhode Island'
		} else if (zipcode >= 29000 && zipcode <= 29999) {
			st = 'SC'
			state = 'South Carolina'
		} else if (zipcode >= 57000 && zipcode <= 57999) {
			st = 'SD'
			state = 'South Dakota'
		} else if (zipcode >= 37000 && zipcode <= 38599) {
			st = 'TN'
			state = 'Tennessee'
		} else if (
			(zipcode >= 75000 && zipcode <= 79999) ||
			(zipcode >= 73301 && zipcode <= 73399) ||
			(zipcode >= 88500 && zipcode <= 88599)
		) {
			st = 'TX'
			state = 'Texas'
		} else if (zipcode >= 84000 && zipcode <= 84999) {
			st = 'UT'
			state = 'Utah'
		} else if (zipcode >= 5000 && zipcode <= 5999) {
			st = 'VT'
			state = 'Vermont'
		} else if (
			(zipcode >= 20100 && zipcode <= 20199) ||
			(zipcode >= 22000 && zipcode <= 24699) ||
			zipcode == 20598
		) {
			st = 'VA'
			state = 'Virgina'
		} else if (
			(zipcode >= 20000 && zipcode <= 20099) ||
			(zipcode >= 20200 && zipcode <= 20599) ||
			(zipcode >= 56900 && zipcode <= 56999)
		) {
			st = 'DC'
			state = 'Washington DC'
		} else if (zipcode >= 98000 && zipcode <= 99499) {
			st = 'WA'
			state = 'Washington'
		} else if (zipcode >= 24700 && zipcode <= 26999) {
			st = 'WV'
			state = 'West Virginia'
		} else if (zipcode >= 53000 && zipcode <= 54999) {
			st = 'WI'
			state = 'Wisconsin'
		} else if (zipcode >= 82000 && zipcode <= 83199) {
			st = 'WY'
			state = 'Wyoming'
		}
		return st
	}

	initAllData() {
		this.data = {
			assembledCategories: [],
			zone: [],
			vendors: [],
			sqVendors: [],
			meals: [],
			ingredients: [],
			sqFoodTrucks: [],
			sqRestaurants: [],

			categories: [],
			assembledvendors: [],
			assembledMeals: [],

			filteredMeals: [],
			filteredResto: [],
			filteredTruck: [],
			tangyRestro: [],

			selectedPlan: {
				meals: [],
				orders: [],
			},
			sqOrders: {
				orders: [],
			},
			order: {
				hcOrder: {},
				sqOrder: {},
				subTotal: 0,
				tax: 0,
				delivery: 0,
				tips: 0,
				total: 0,
			},
		}

		this.userHub = {
			address: '',
			city: '',
			zip: '',
			state: '',
			zone: '',
			zoneId: '',
			hubs: [],
		}

		this.filters = {
			dietary: '',
			spice: '',
			price: '',
			rating: 0,
			deliveryDay: '',
		}

		this.tagsList = [
			{
				name: 'Vegan (No animal products like eggs, butter, ghee, honey, or milk)',
				selected: false,
				icon: 'vegan.png',
			},
			{ name: 'Vegetarian', selected: false, icon: 'vegetarian.png' },
			{ name: 'Dairy Free', selected: false, icon: 'dairy.png' },
			{ name: 'Contains Nuts', selected: false, icon: 'peanut.png' },
			{
				name: 'Gluten Free (No gluten, grain proteins)',
				selected: false,
				icon: 'gluten.png',
			},
			{
				name: 'Halal (Meat prepared in accordance with Islamic law)',
				selected: false,
				icon: 'hala.png',
			},
			{
				name: 'Sugar Free (Diabetes Friendly)',
				selected: false,
				icon: 'sugar-free.png',
			},
			{
				name: 'Keto Friendly',
				selected: false,
				icon: 'keto.png',
			},
		]

		this.totals = {}
		this.query = ''
		this._searchClicked = false
		this._showSearchResults = false
		this._showDishesCalendar = false
		this.refreshCalendarList()
	}

	/**----------------------------------------------------------
	 *
	 * @param meal
	 * @returns
	 */
	async updateUserVendorFavorite(vendorId: string) {
		if (!this.userProvider.loggedInUser.id) {
			this.util.alert(
				'Note',
				'Please sign in to add your favorite dishes.'
			)
			return
		}

		let chefFavorites: any[] = this.userProvider.loggedInUser.chefFavorites
		let found = false
		if (chefFavorites && chefFavorites.length > 0) {
			const idx = chefFavorites.findIndex(
				(el) => el.vendorId === vendorId
			)
			if (idx >= 0) {
				found = true
				chefFavorites[idx].checked = !chefFavorites[idx].checked
			} else {
				chefFavorites.push({ vendorId, checked: true })
			}
		} else chefFavorites = [{ vendorId, checked: true }]

		this.userProvider.loggedInUser.chefFavorites = chefFavorites
		this.api.updateUser(this.userProvider.loggedInUser.id, {
			chefFavorites,
		})
	}

	/**-------------------------------------------------------------
	 *
	 * @param mealId
	 * @returns
	 */
	getUserVendorFavorite(vendorId: string) {
		if (!this.userProvider.loggedInUser.id) return
		let fav: any = {}
		let chefFavorites: any[] = this.userProvider.loggedInUser.chefFavorites
		if (chefFavorites && chefFavorites.length > 0) {
			fav = this.userProvider.loggedInUser.chefFavorites.find(
				(el) => el.vendorId === vendorId
			)
		}
		return fav && fav.checked ? true : false
	}

	/**--------------------------------------------------------------
	 *
	 * @param usl
	 */
	async updateRec(usl: any) {
		this.userProvider.loggedInUser.searchList = usl
		try {
			this._spin = true
			await this.api.updateOne(
				'users',
				this.userProvider.loggedInUser.id,
				{
					searchList: usl,
				}
			)
			this._spin = false
		} catch (e) {
			this._spin = false
			console.log('saving data err', e)
		}
	}

	async logout() {
		const alert = await this.util.createAlert(
			'Logout',
			false,
			'Please confirm your log out?',
			{
				text: 'Cancel',
				role: 'cancel',
				cssClass: 'secondary',
				handler: (res) => {},
			},
			{
				text: 'Yes',
				handler: async () => {
					this.initAllData()
					this.userProvider.createNewEmptyUser()
					this.util.navigateRoot('/auth')
					this.userProvider.logout()
				},
			}
		)

		await alert.present()
	}

	//------------------------------------------------------------
	replaceUndefined(obj) {
		for (const [key, value] of Object.entries(obj)) {
			if (value === undefined) {
				obj[key] = ''
			}
		}
		return obj
	}

	async AddSqItemToCart(item: any) {
		await this.replaceUndefined(item)
		let idx = -1
		if (
			this.data.sqOrders?.orders &&
			this.data.sqOrders?.orders.length > 0
		) {
			idx = this.data.sqOrders.orders.findIndex(
				(el: any) => el.id === item.id && el.locId === item.locId
			)
		}

		if (idx > -1) {
			this.data.sqOrders.orders[idx].qty += 1
		} else {
			item.qty = 1
			this.data.sqOrders.orders.push(item)
		}

		console.log('order -=>', this.data.sqOrders.orders)

		if (this.userProvider?.loggedInUser?.id) {
			this.api.updateUser(this.userProvider.loggedInUser.id, {
				sqCart: this.data.sqOrders.orders,
				previousZip: this.userZip,
			})
		}
	}

	/**-------------------------------------------------------------
	 *
	 * @param itemId
	 */
	removeSqItemFromCart(item: any) {
		let idx = -1

		if (
			this.data.sqOrders.orders &&
			this.data.sqOrders?.orders.length > 0
		) {
			idx = this.data.sqOrders.orders.findIndex(
				(el: any) => el.id === item.id && el.locId === item.locId
			)
		}
		if (idx > -1) {
			if (this.data.sqOrders.orders[idx].qty >= 1) {
				this.data.sqOrders.orders[idx].qty -= 1
				if (this.data.sqOrders.orders[idx].qty === 0)
					this.data.sqOrders.orders.splice(idx, 1)
				if (this.data.sqOrders.orders.length === 0) this.carts = []
				console.log('order -=>', this.data.sqOrders.orders)
			}
		}

		if (this.userProvider?.loggedInUser?.id) {
			this.api.updateUser(this.userProvider.loggedInUser.id, {
				sqCart: this.data.sqOrders.orders,
			})
		}
	}

	/**---------------------------------------------------------------
	 *
	 * @param item
	 * @returns
	 */
	countSqItemsInCarts(item: any) {
		if (
			this.data.sqOrders?.orders &&
			this.data.sqOrders?.orders.length === 0
		)
			return 0

		let count = 0
		const i = this.data.sqOrders.orders.find(
			(el: any) => el.id === item.id && el.locId === item.locId
		)
		if (i && i.id) count = i.qty ? i.qty : 0
		return count
	}

	/**------------------------------------------------------------------
	 *
	 * @param id
	 * @param date
	 * @param spiceLevel
	 * @param meals
	 * @returns
	 */
	findHCMealQty(id: string, date: string, spiceLevel: number, meals?: any) {
		const m =
			meals && meals.length > 0 ? meals : this.data.selectedPlan.meals
		return m.filter(
			(el) =>
				el.id === id &&
				el.scheduledDate === date &&
				el.spiceLevel === spiceLevel
		).length
	}

	/**---------------------------------------------------------------------
	 *
	 * @returns
	 */
	deliveryTotal() {}

	//---------------------
	//---------------------HC
	//---------------------

	submitChosenMeals() {
		if (this.data.selectedPlan.meals.length > 0)
			this.util.navigateRoot('/hc-checkout')
	}

	checkZoneData() {
		console.log('=> checkZoneData called')
		return new Promise<any>((resolved, rejected) => {
			if (!this.data.zone || this.data.zone.length === 0) {
				this.api.getZoneList('zone').subscribe(
					(res) => {
						if (res && res.length > 0) {
							this.data.zone = res
							resolved(true)
						} else resolved(false)
					},
					(err) => {
						rejected(false)
					}
				)
			} else resolved(true)
		})
	}

	async triggerInvalidZipCode(reason: string) {
		this._invalidZip = true
		if (
			this.data.selectedPlan.meals &&
			this.data.selectedPlan.meals.length > 0
		)
			this.data.selectedPlan.meals = []
		this.fi = []
		//this.util.showToast(reason)
		this.util.navigateRoot('/dishes')
	}

	/**-----------------------------------------------------------
	 *
	 * @param dishQuery
	 */
	searchHFDishesItems(dishQuery: string) {
		this.data.filteredMeals.forEach((el: any) => (el.hide = false))
		if (dishQuery.length > 0) {
			this.data.filteredMeals.forEach((el: any) => {
				if (
					el.name
						.toLocaleLowerCase()
						.indexOf(dishQuery.toLocaleLowerCase()) < 0
				)
					el.hide = true
			})
		}
		//logs
		const a = this.data.filteredMeals.filter((el) => !el.hide)
		console.log('seached items -=>>>>>>>', '(' + dishQuery + ')', a)
	}

	/**
	 *
	 *  find zone hub & check if user is nearBy
	 * @param zipString
	 * @returns
	 */
	async findZoneHub(zipcode: string, _asseble: boolean, _isChef?: boolean) {
		return new Promise<any>(async (resolved, rejected) => {
			console.log('=> find ZoneHub called', zipcode)
			this._isNearBy = false
			console.log('----------------->', this._isNearBy)

			this.userHub = {
				address: '',
				city: '',
				zip: '',
				state: '',
				zone: '',
				zoneId: '',
				hubs: [],
			}
			const st = this.findStateFromZip(zipcode)
			if (st) {
				this.userHub.zip = zipcode
				this.userHub.state = st

				this.data.zone.forEach((el) => {
					el.states.forEach((state) => {
						if (this.userHub.state === state) {
							this.userHub.zone = el.zone
							this.userHub.hubs = el.hubs
							this.userHub.zoneId = el.id
						}
					})
				})
				console.log('1 userHub ->', this.userHub)
			} else {
				console.log('2 userHub ->', this.userHub)
				this.triggerInvalidZipCode('State not defined')
				resolved(false)
			}

			// convert zip code to lat lng
			await this.util.convertAddressToLatLng(zipcode).then(
				(coords: any) => {
					if (coords && coords.lat && coords.lng) {
						const a = {
							latitude: coords.lat,
							longitude: coords.lng,
						}
						if (this.userHub.hubs.length > 0) {
							// calc distance betwen 2 points for each hub
							this.userHub.hubs.forEach(
								async (el: any, index: number) => {
									const distance =
										await this.util.getDistanceBtw2points(
											a,
											el.latLng
										)
									el.distance = Number(
										((0.621371 * distance) / 1000).toFixed(
											2
										)
									)
									el.nearBy = el.distance < 35 ? true : false
									if (
										index ===
										this.userHub.hubs.length - 1
									) {
										const nearByHubs =
											this.userHub.hubs.filter(
												(obj: any) =>
													obj.nearBy === true
											)
										if (nearByHubs.length > 0) {
											this.userHub.hubs = nearByHubs
											this._isNearBy = true
											console.log(
												'------>',
												this._isNearBy
											)
											if (_asseble) {
												this._showZipSearchForm = false
												this.assembleChefMealData(
													zipcode,
													_isChef
												)

												this.dataLoaded = true
												resolved(true)
											}
										} else
											this.triggerInvalidZipCode(
												'Nearby hubs not found'
											)
									}
								}
							)
						} else if (_asseble)
							this.triggerInvalidZipCode(
								'No Nearby Hubs Available'
							)
					} else if (_asseble)
						this.triggerInvalidZipCode(
							'Your Geolocation is not define, pleaae try again'
						)
				},
				(err) => {
					resolved(false)
					this.triggerInvalidZipCode('Error getting your geolocation')
				}
			)
			resolved(false)
		})
	}

	removeHCMeal(meal: any) {
		const foundIndex = this.data.selectedPlan.meals.findIndex(
			(x) => x.id === meal.id && x.scheduledDate === this.chosenDate
		)

		// update meals
		if (foundIndex >= 0) {
			this.data.selectedPlan.meals.splice(foundIndex, 1)
		}

		// update orders
		let l = _.cloneDeep(this.data.selectedPlan.meals)
		this.data.selectedPlan.orders = this.uniqBy(l)
		console.log('l data >>>>>>>>>>', this.data)
		if (this.userProvider?.loggedInUser?.id) {
			this.api.updateUser(this.userProvider.loggedInUser.id, {
				hcCart: this.data.selectedPlan,
			})
		}
	}

	removeMealInCart(meal) {
		const foundIndex = this.data.selectedPlan.meals.findIndex(
			(x) =>
				x.id === meal.id &&
				x.scheduledDate === meal.scheduledDate &&
				x.spiceLevel === meal.spiceLevel
		)

		// update meals
		if (foundIndex >= 0) {
			this.data.selectedPlan.meals.splice(foundIndex, 1)
		}

		// update orders
		let l = _.cloneDeep(this.data.selectedPlan.meals)
		this.data.selectedPlan.orders = this.uniqBy(l)
		console.log('l data >>>>>>>>>>', this.data)

		if (this.userProvider?.loggedInUser?.id) {
			this.api.updateUser(this.userProvider.loggedInUser.id, {
				hcCart: this.data.selectedPlan,
			})
		}
	}
	/**-----------------------------------------------------------
	 *
	 * @param meal
	 * @returns
	 */
	findHCtotal(meal: any) {
		if (
			this.data?.selectedPlan?.meals &&
			this.data?.selectedPlan?.meals.length > 0
		) {
			return this.data.selectedPlan.meals.filter(
				(el: any) =>
					el.id === meal.id && el.scheduledDate === this.chosenDate
			).length
		} else return 0
	}

	/**-----------------------------------------------------------
	 *
	 * @param a
	 */
	async addHCMeal(meal: any) {
		console.log(this.data.selectedPlan)
		let a: any = {}
		a = Object.assign({}, meal)
		a.chosenDate = false
		a.qty = 1
		a.zone = a.vendor.zone
		a.hub = a.vendor.hub
		a.scheduledDate = this.chosenDate
		a.cost = typeof a.cost === 'string' ? Number(a.cost) : a.cost
		a.dishId = Math.round(1000000000000 * Math.random()).toString()
		this.data.selectedPlan.meals.push(a)

		// update orders
		let l = _.cloneDeep(this.data.selectedPlan.meals)
		this.data.selectedPlan.orders = this.uniqBy(l)

		console.log('l data >>>>>>>>>>', this.data)

		if (this.userProvider?.loggedInUser?.id) {
			this.api.updateUser(this.userProvider.loggedInUser.id, {
				hcCart: this.data.selectedPlan,
				previousZip: this.userZip,
			})
		}
	}

	async addMealInCart(meal: any) {
		meal.chosenDate = false

		// update meals
		meal.qty = 1
		this.data.selectedPlan.meals.push(meal)

		// update orders
		let l = _.cloneDeep(this.data.selectedPlan.meals)
		this.data.selectedPlan.orders = this.uniqBy(l)
		console.log('2 data >>>>>>>>>>', this.data.selectedPlan)
	}

	/**-----------------------------------------------------------
	 *
	 */
	// get cartCount() {
	// 	const t1 =
	// 		this.data.sqOrders.orders && this.data.sqOrders.orders.length
	// 			? this.data.sqOrders.orders.length
	// 			: 0

	// 	let t2 = 0
	// 	this.data.selectedPlan.orders.forEach((el: any) => {
	// 		t2 += el.items && el.items.length > 0 ? el.items.length : 0
	// 	})

	// 	return t1 + t2
	// }

	get cartCount() {
		const t1 =
			this.data.sqOrders.orders && this.data.sqOrders.orders.length > 0
				? this.data.sqOrders.orders.reduce(
						(sum: number, order: any) => sum + (order.qty || 0),
						0
				  )
				: 0

		const t2 =
			this.data.selectedPlan.orders &&
			this.data.selectedPlan.orders.length > 0
				? this.data.selectedPlan.orders.reduce(
						(sum: number, order: any) => {
							const itemsQty =
								order.items && order.items.length > 0
									? order.items.reduce(
											(itemSum: number, item: any) =>
												itemSum + (item.qty || 0),
											0
									  )
									: 0
							return sum + itemsQty
						},
						0
				  )
				: 0

		return t1 + t2
	}

	/**-----------------------------------------------------------
	 *
	 * @param i
	 */
	clearScr(i: number) {
		this._showDishesCalendar = i === 1 ? !this._showDishesCalendar : false
		// this.meal Service._showDishesFilters =
		// 	i === 2 ? !this.meal Service._showDishesFilters : false
	}

	selectDay(dt: any, day: string) {
		this.chosenDate = dt
		let selectedDate = day.substring(0, 2)
		console.log('seleeteddAte', selectedDate, this.chosenDate)
		this.filters.deliveryDay = day.substring(0, 2)
	}

	resetSearch() {
		this.searchStr = ''
		this.selectedCategoryId = ''
		this.searchDishesItems()
	}

	findMealQty(id: string, date: string, spiceLevel: number): number {
		const m = this.data.selectedPlan.meals
		return m.filter(
			(el: any) =>
				el.id === id &&
				el.scheduledDate === date &&
				el.spiceLevel === spiceLevel
		).length
	}

	//  by vendor

	uniqBy(list: any) {
		let myMeals = []
		if (list && list.length > 0) {
			//
			//  by vendor
			const res = _(list)
				.groupBy('vendorId')
				.map((meals: any, vendorId: string) => {
					return {
						vendorId,
						meals,
					}
				})
				.value()

			const myList = res && res.length > 0 ? res : []

			// add vendor's data
			let item: any
			myList.forEach((el: any) => {
				el.items = []
				if (el.meals && el.meals.length > 0) {
					item = el.meals[0]
					el.name =
						(item.vendor.firstName ? item.vendor.firstName : '') +
						' ' +
						(item.vendor.lastName ? item.vendor.lastName : '')

					el.photo = item.vendor?.profileImage
						? item.vendor?.profileImage
						: ''
					el.restoName = item.vendor?.restoName ?? ''
					// group by item id
					el.meals.forEach((m: any) => {
						//rest meal Qty
						m.qty = 1
						const idx = el.items.findIndex(
							(f) =>
								m.id === f.id &&
								m.scheduledDate === f.scheduledDate &&
								m.spiceLevel === f.spiceLevel
						)
						if (idx < 0) el.items.push(m)
						else el.items[idx].qty += 1
					})

					delete el.meals

					// show hide dates
					el.items.sort(this.sortArray)
					let scheduledDate
					el.items.forEach((el) => {
						if (scheduledDate !== el.scheduledDate) {
							el.showDate = true
							scheduledDate = el.scheduledDate
						} else el.showDate = false
					})

					myMeals.push(el)
				}
			})
		}
		this.cartCalc()
		return myMeals
	}

	cartCalc() {
		//
		// home chef----------------------
		const hc = this.data.selectedPlan
		let total = 0

		if (hc?.orders && hc.orders.length > 0) {
			hc.orders.forEach((el: any) => {
				el.total = 0
				el.tips = 0
				const cartTotal = el.items.reduce(
					(total: number, item: any) =>
						total + item?.qty * item?.cost,
					0
				)

				el.total = cartTotal
				total += cartTotal
			})
		}

		hc.subTotal = total
		hc.tax = 0
		hc.delivery = 0
		hc.tips = 0
		hc.total = hc.subTotal + hc.tax + hc.delivery + hc.tips
		this.order.hcOrder = hc

		// square-------------------------------

		total = 0
		let o = this.carts
		if (o && o.length > 0) {
			o.forEach((el: any) => {
				el.total = 0
				el.tips = 0
				const cartTotal = el.items.reduce(
					(total: number, item: any) =>
						total + item?.qty * item?.vp?.price,
					0
				)

				el.total = cartTotal / 100
				total += el.total
			})
		}

		const sq: any = { carts: this.carts }

		sq.subTotal = total
		sq.tax = 0
		sq.delivery = 0
		sq.tips = 0
		sq.total = hc.subTotal + hc.tax + hc.delivery + hc.tips
		this.order.sqOrder = sq

		const t = hc.subTotal + sq.subTotal

		// upd tips
		const index = this.ct.findIndex((ct) => ct.t)
		if (index >= 0 && index < 4)
			this.tips = Math.round(t * this.ct[index].val) / 100

		this.order.subTotal = Math.floor(t * 1000) / 1000
		this.order.tax = Math.round(7.75 * t) / 100
		this.order.delivery = 0
		this.order.tips = this.tips
		this.order.total =
			Math.floor(
				(this.order.subTotal +
					this.order.tax +
					this.order.delivery +
					this.order.tips) *
					1000
			) / 1000

		this.data.order = this.order
	}

	/**----------------------------------------------------------
	 *
	 */
	reGroupMeals() {
		let list = _.cloneDeep(this.data.selectedPlan.meals)
		this.reGrMeals = []
		let today = new Date()
		const res1 = _(list)
			.groupBy('scheduledDate')
			.map((items: any, scheduledDate: string) => {
				const scheduled = new Date(scheduledDate)
				let valid = true
				scheduled.setHours(0, 0, 0, 0)
				if (scheduled <= today) {
					valid = false
				}
				return {
					scheduledDate,
					items,
					valid,
				}
			})
			.value()
		res1.forEach((el: any) => {
			const it = el.items
			const sd = el.scheduledDate
			const res2 = _(it)
				.groupBy('vendorId')
				.map((m: any, vendorId: string) => {
					let vId = ''
					let vName = ''
					let restoName = ''
					if (m && m.length > 0) {
						vId = vendorId
						vId = m[0].vendorId
						vName =
							m[0].vendor.firstName + ' ' + m[0].vendor.lastName
						restoName = m[0].vendor.restoName
					}

					return {
						vendorId: vId,
						name: vName,
						items: m,
						restoName,
					}
				})
				.value()

			//calc totals
			res2.forEach((s: any) => {
				let myItems = []
				s.items.forEach((m: any) => {
					const idx = myItems.findIndex(
						(f: any) =>
							m.id === f.id &&
							m.spiceLevel === f.spiceLevel &&
							m.scheduledDate === f.scheduledDate
					)

					if (idx < 0) myItems.push(m)
					else myItems[idx].qty += 1
				})

				let total = 0
				let len = 0
				myItems.forEach((el) => {
					len += el.qty
					total += el.cost * el.qty
				})

				s.total = total
				s.len = len
				s.myItems = myItems.sort((a, b) => (a.name < b.name ? -1 : 1))
			})

			el.items = res2
			this.reGrMeals.push(el)
		})

		// sort  by date

		this.reGrMeals.sort(
			(a, b) =>
				new Date(a.scheduledDate).getTime() -
				new Date(b.scheduledDate).getTime()
		)

		console.log('<> reGrMeals  =->', this.reGrMeals)
	}

	mySpice(spice) {
		return Number(spice)
	}

	/**----------------------------------------------------------
	 *
	 */
	reGrOrderHistory(list: any) {
		let myItems = []

		const res1 = _(list)
			.groupBy('scheduledDate')
			.map((items: any, scheduledDate: string) => {
				return {
					scheduledDate,
					items,
				}
			})
			.value()

		res1.forEach((el: any) => {
			const it = el.items
			const sd = el.scheduledDate
			const res2 = _(it)
				.groupBy('vendorId')
				.map((m: any, vendorId: string) => {
					let vId = ''
					let vName = ''
					let restoName = ''
					if (m && m.length > 0) {
						vId = vendorId
						vId = m[0].vendorId
						vName =
							m[0].vendor.firstName + ' ' + m[0].vendor.lastName
						restoName = m[0].vendor.restoName
					}

					return {
						vendorId: vId,
						name: vName,
						items: m,
						restoName: restoName,
					}
				})
				.value()

			//calc totals
			res2.forEach((s: any) => {
				let myItems = []
				s.items.forEach((m: any) => {
					const idx = myItems.findIndex(
						(f: any) =>
							m.id === f.id &&
							m.spiceLevel === f.spiceLevel &&
							m.scheduledDate === f.scheduledDate
					)

					if (idx < 0) myItems.push(m)
					else myItems[idx].qty += 1
				})

				let total = 0
				let len = 0
				myItems.forEach((el) => {
					len += el.qty
					total += el.cost * el.qty
				})

				s.total = total
				s.len = len
				s.myItems = myItems.sort((a, b) => (a.name < b.name ? -1 : 1))
			})

			el.items = res2
			myItems.push(el)
		})

		// sort  by date

		myItems.sort(
			(a, b) =>
				new Date(a.scheduledDate).getTime() -
				new Date(b.scheduledDate).getTime()
		)

		console.log('HISTO >>>>> reGrOrders  history =->', myItems)
		return myItems
	}

	add30min(date: any): any {
		const newDateObj = moment(date).add(30, 'm').toDate()
		const d2 = this.datePipe.transform(newDateObj, 'MM/dd/yyyy hh:mm a')
		return d2
	}

	menuTheme(index: number) {
		const currentUrl = this.router.url + '/' + index
		console.log('currentUrl -=>', currentUrl)
		this.util.navigateForward(currentUrl)
	}

	async checkUserStatus() {
		const u = this.userProvider.loggedInUser
		if (u.id) {
			if (u.active == false) {
				await this.util.showAlert(
					'User Inactive',
					'Your account is inactive. Please contact the Help Center for assistance.'
				)

				setTimeout(() => {
					this.userProvider.logout()
					this.util.navigateForward('auth')
				}, 5000)
			}
			await this.userProvider.listenerActiveUser('users', u.id)
		}
	}
}
