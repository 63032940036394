import { Injectable } from '@angular/core'
import {
	Camera,
	CameraResultType,
	CameraPlugin,
	CameraSource,
} from '@capacitor/camera'
import { Platform } from '@ionic/angular'
import { Observable } from 'rxjs'
import { timeout } from 'rxjs/operators'
import { StorageService } from '../api/firestorage.service'

@Injectable({
	providedIn: 'root',
})
export class PhotoUploadService {
	constructor(
		private storageServ: StorageService,
		private platform: Platform
	) { }

	uploadPhotoPro(name): Observable<any> {
		return new Observable<any>((observer) => {
			this.takePic().subscribe(
				(capturedPhoto) => {
					console.log('obs capturedPhoto ->', capturedPhoto);
					
					 // Base64 header validation for common image types
					 const base64Header = capturedPhoto.base64String.slice(0, 15); // Extract a small portion for validation
					 const isValidImage = base64Header.startsWith('iVBORw0KGgo') || 
										  base64Header.startsWith('/9j/')
										//   ||  base64Header.startsWith('R0lGOD');
	 
					 if (!isValidImage) {
						 console.error('Invalid file type. Only image files are accepted.');
						 observer.error('Invalid file type. Please upload a valid image.');
						 return;
					 }
	
					const date = new Date().valueOf();
					const imageName = name.replace(/\s/g, '') + '_' + date + '.png';
					const blob = this.dataURItoBlob(capturedPhoto.base64String);
	
					this.storageServ.uploadContent(blob, imageName)
						.then(async (success: any) => {
							console.log('url ->', success.url);
							observer.next(success.url);
						})
						.catch(async (err) => {
							observer.error(err);
						});
				},
				(e) => observer.error(e)
			).add(() => {
				console.log('end uploading');
			});
		});
	}
	

	private takePic(): Observable<any> {
		return new Observable<any>((obs) => {
			Camera.checkPermissions()
				.then(async (res) => {
					console.log('permission ->', res)
					Camera.getPhoto({
						resultType: CameraResultType.Base64,
						source:
							this.platform.is('android') ||
								this.platform.is('ios')
								? CameraSource.Prompt
								: CameraSource.Camera,
						width: 700,
						allowEditing: true,
						quality: 100,
					})
						.then((pic) => {
							console.log('success pic ->', pic)
							obs.next(pic)
						})
						.catch(async (err) => {
							console.log('1 err pic ->')
							obs.error(err)
						})
				})
				.catch(async (e) => {
					console.log('2 err pic ->', e)
					obs.error(e)
				})
		})
	}

	dataURItoBlob(dataURI) {
		const byteString = window.atob(dataURI)
		const arrayBuffer = new ArrayBuffer(byteString.length)
		const int8Array = new Uint8Array(arrayBuffer)
		for (let i = 0; i < byteString.length; i++) {
			int8Array[i] = byteString.charCodeAt(i)
		}
		const blob = new Blob([int8Array], { type: 'image/png' })
		return blob
	}
}
