import { APP_INITIALIZER, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { RouteReuseStrategy } from '@angular/router'
import { IonicModule, IonicRouteStrategy } from '@ionic/angular'
import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { IonicStorageModule } from '@ionic/storage-angular'
import { AngularFireModule } from '@angular/fire/compat'
import { AngularFirestoreModule } from '@angular/fire/compat/firestore'
import { AngularFireAuthModule } from '@angular/fire/compat/auth'
import { AngularFireStorageModule } from '@angular/fire/compat/storage'
import { AuthenticationService } from './services/api/firebase-authentication.service'
import { FirestoreService } from './services/api/firestore.service'
import { UserProviderService } from './services/userProvider/user-provider.service'
import { HttpClientModule } from '@angular/common/http'
import { NgxStripeModule } from 'ngx-stripe'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { DatePipe } from '@angular/common'
import { provideAuth, getAuth } from '@angular/fire/auth'
import { environment } from 'environments/environment'
import { StorageService } from './services/api/firestorage.service'
import { SharedModule } from './shared-modules/shared.module'

@NgModule({
	declarations: [AppComponent],
	exports: [],
	imports: [
		BrowserModule,
		IonicModule.forRoot({ animated: false }),
		AppRoutingModule,
		AngularFireModule.initializeApp(environment.firebaseConfig),
		AngularFirestoreModule,
		AngularFireAuthModule,
		AngularFireStorageModule,
		HttpClientModule,
		NgxStripeModule.forRoot(environment.stripe.publishable),
		IonicStorageModule.forRoot({ name: 'dailymealsdb' }),
		provideAuth(() => getAuth()),
		FormsModule,
		ReactiveFormsModule,
		SharedModule,
	],
	providers: [
		AuthenticationService,
		FirestoreService,
		StorageService,
		UserProviderService,
		DatePipe,
		Storage,
		{
			provide: APP_INITIALIZER,
			useFactory: initUserProviderFactory,
			deps: [UserProviderService],
			multi: true,
		},
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
	],
	bootstrap: [AppComponent],
})
export class AppModule {}

export function initUserProviderFactory(provider: UserProviderService) {
	return () => provider.load()
}
