import { Component, OnInit } from '@angular/core'
import { AngularFireAuth } from '@angular/fire/compat/auth'
import { MealsService } from 'app/services/meals/meals.service'
import { UserProviderService } from 'app/services/userProvider/user-provider.service'
import { UtilService } from 'app/services/util/util.service'

@Component({
	selector: 'reset-refer',
	templateUrl: './reset.page.html',
	styleUrls: ['./reset.page.scss'],
})
export class ResetPage implements OnInit {
	showPassword: boolean = false
	email
	_sent = false
	constructor(
		public userProvider: UserProviderService,
		private util: UtilService,
		public mealService: MealsService,
		private fireAuth: AngularFireAuth
	) {}

	ngOnInit() {
		this.email = ''
	}

	resetPass() {
		console.log(this.email)
		if (
			!this.email ||
			this.email.indexOf('.') < 0 ||
			this.email.indexOf('@') < 0
		) {
			this.util.showToast('Wrong email, Please correct & try again')
			return
		}
		this.fireAuth
			.sendPasswordResetEmail(this.email)
			.then((res) => {
				this._sent = true
			})
			.catch((err) => {
				this._sent = false
				this.util.showAlert('', 'Email not registered, please sign up.')
			})
	}
}
