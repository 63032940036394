<div (click)="mealService.dismiss()" class="close-modal">
	<ion-icon name="close"></ion-icon>
</div>

<ion-content #top class="center">
	<div class="reset f-regular">
		<div class="margin-top-4" *ngIf="!_sent">
			<span class="poppins-med f1-0">
				Enter your email<br />to reset your password.
			</span>

			<form #authForm="ngForm" class="margin-top-2">
				<ion-item class="ii" style="margin: 0 auto">
					<ion-input
						name="email"
						type="email"
						pattern="[A-Za-z0-9._%+\-]+@[A-Za-z0-9.\-]+\.[A-Za-z]{2,}$"
						email
						required
						ngModel
						[(ngModel)]="email"
					></ion-input>
				</ion-item>

				<button
					type="button"
					(click)="resetPass()"
					class="custom-btn-light"
					[ngClass]="authForm.valid ? '' : 'invalid'"
					[disabled]="authForm.valid ? false : true"
				>
					Reset Password
				</button>
			</form>
		</div>
		<div class="margin-top-4 center" *ngIf="_sent">
			<div style="font-size: 3rem !important">Thanks!</div>
			<div color="grey f1-9">
				Please check your email<br />
				click on the reset link
			</div>
		</div>
	</div>
</ion-content>
