import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import { ApiService } from 'app/services/api/api.service'
import { MealsService } from 'app/services/meals/meals.service'
import { UserProviderService } from 'app/services/userProvider/user-provider.service'
import { UtilService } from 'app/services/util/util.service'

@Component({
	selector: 'app-edit',
	templateUrl: './edit.page.html',
	styleUrls: ['./edit.page.scss'],
})
export class EditPage implements OnInit {
	showPassword: boolean = false
	user = {
		email: '',
		password: '',
		zipcode: '',
	}

	shipping: any
	_butClicked = false
	newShipping: any = {}
	_asSameAdr = false

	autoCompleteItems = []
	autoCompleted: Observable<any>
	autoCompleteSubject$ = new Subject()
	@ViewChild('outsideListener') outsideListener: ElementRef
	andClickEvent(event) {
		if (
			this.outsideListener &&
			!this.outsideListener.nativeElement.contains(event.target)
		) {
			this.autoCompleteItems = []
		}
	}
	constructor(
		public userProvider: UserProviderService,
		private util: UtilService,
		public mealService: MealsService,
		private api: ApiService,
		private __zone: NgZone
	) {}

	ngOnInit() {
		this._asSameAdr = false
		this.autoCompleted = this.autoCompleteSubject$.pipe(debounceTime(100))
		this.autoCompleted.subscribe((res) => {
			this.getAutoCompleteList(res)
		})
	}

	search(event) {
		const searchString = event.target.value
		this.autoCompleteSubject$.next(searchString)
	}

	async getAutoCompleteList(searchString) {
		if (searchString !== '') {
			const predictions = await this.util.getGooglePlaceAutoCompleteList(
				searchString,
				{},
				'US'
			)
			this.__zone.run(() => {
				this.autoCompleteItems = []

				if (predictions !== null) {
					predictions.forEach((prediction, i) => {
						// return;
						if (prediction.types.includes('street_address')) {
							// predictions that include 'street_address' should be included
							const splitDescription = this.splitWithTail(
								prediction.description
							)
							prediction.description = splitDescription

							this.autoCompleteItems.push(prediction)
						} else {
							// predictions that don't include 'street_address' will go through the check

							var typeCounter = 0
							if (prediction.types.includes('geocode')) {
								typeCounter++
							}
							if (prediction.types.includes('route')) {
								typeCounter++
							}
							if (prediction.types.length > typeCounter) {
								if (prediction.types.includes('locality')) {
								} else {
									if (
										prediction.description.match(/[0-9]/i)
									) {
										const splitDescription =
											this.splitWithTail(
												prediction.description
											)

										prediction.description =
											splitDescription

										this.autoCompleteItems.push(prediction)
									}
								}
							}
						}
					})
				}
			})
		}
	}

	async chooseItem(place) {
		this.autoCompleteItems = []
		const placeDetails = await this.util.reverseAddressSearch(
			place.description[0] + ',' + place.description[1]
		)
		this.newShipping.address =
			placeDetails.number + ' ' + placeDetails.street
		this.newShipping.city = placeDetails.city
		this.newShipping.state = placeDetails.state
		this.newShipping.zip = placeDetails.zipcode
		console.log('Place Details -> ', placeDetails)
	}

	splitWithTail(str) {
		var parts = str.split(',')
		var tail = parts.slice(1).join(',')
		var result = parts.slice(0, 1)
		result.push(tail)
		return result
	}

	formatPhone($event) {
		let phone = $event.target.value.replace(/[^\d]/g, '')
		let len = phone.length
		phone = len > 10 ? phone.substr(0, 10) : phone
		phone =
			len > 3 ? phone.substr(0, 3) + '-' + phone.substr(3, len) : phone
		phone =
			len > 6
				? phone.substr(0, 7) + '-' + phone.substr(7, len + 1)
				: phone
		this.newShipping.phone = phone
	}

	save() {
		if (this.newShipping.address) {
			if (!this.userProvider.loggedInUser.firstName)
				this.userProvider.loggedInUser.firstName =
					this.newShipping.firstName
			if (!this.userProvider.loggedInUser.lastName)
				this.userProvider.loggedInUser.lastName =
					this.newShipping.lastName
			if (!this.userProvider.loggedInUser.addressLine1)
				this.userProvider.loggedInUser.addressLine1 =
					this.newShipping.address
			if (!this.userProvider.loggedInUser.addressCity)
				this.userProvider.loggedInUser.addressCity =
					this.newShipping.city
			if (!this.userProvider.loggedInUser.addressState)
				this.userProvider.loggedInUser.addressState =
					this.newShipping.state
			if (!this.userProvider.loggedInUser.addressZipcode)
				this.userProvider.loggedInUser.addressZipcode =
					this.newShipping.zip
			if (!this.userProvider.loggedInUser.phoneNumber)
				this.userProvider.loggedInUser.phoneNumber =
					this.newShipping.phone

			// initial add
			if (this.mealService.updateUserData.page) {
				if (this.mealService.updateUserData.page === 'shipping') {
					this.userProvider.loggedInUser.shipping = this.newShipping
					if (this._asSameAdr)
						this.userProvider.loggedInUser.billing =
							this.newShipping
				} else {
					this.userProvider.loggedInUser.billing = this.newShipping
					if (this._asSameAdr)
						this.userProvider.loggedInUser.shipping =
							this.newShipping
				}
			}

			// check if update
			let itemIndex = -1
			if (this.newShipping.id) {
				this.userProvider.loggedInUser.shippingList.forEach(
					(el, index) => {
						if (el.id === this.newShipping.id) itemIndex = index
					}
				)
			}

			if (itemIndex >= 0) this.update(itemIndex)
			else {
				this.newShipping.id = Math.round(
					10000000 * Math.random()
				).toString()
				this.userProvider.loggedInUser.shippingList.unshift(
					this.newShipping
				)
				this.updateUser()
			}
		}
	}

	cancel() {
		this._butClicked = false
		this._asSameAdr = false
		this.newShipping = {}
	}

	add(adr) {
		this._butClicked = true
		if (adr === 'shipping') {
			this.mealService.updateUserData = {
				page: 'shipping',
				action: 'add',
			}
			this._butClicked = true
			this.newShipping = {
				id: '',
				firstName: this.userProvider.loggedInUser.firstName
					? this.userProvider.loggedInUser.firstName
					: '',
				lastName: this.userProvider.loggedInUser.lastName
					? this.userProvider.loggedInUser.lastName
					: '',
				address: this.userProvider.loggedInUser.lastName
					? this.userProvider.loggedInUser.addressLine1
					: '',
				city: this.userProvider.loggedInUser.lastName
					? this.userProvider.loggedInUser.addressCity
					: '',
				state: this.userProvider.loggedInUser.lastName
					? this.userProvider.loggedInUser.addressState
					: '',
				zip: this.userProvider.loggedInUser.lastName
					? this.userProvider.loggedInUser.addressZipcode
					: '',
				phone: this.userProvider.loggedInUser.lastName
					? this.userProvider.loggedInUser.phoneNumber
					: '',
			}
		} else {
			this.mealService.updateUserData = {
				page: 'billing',
				action: 'add',
			}
			this.newShipping = {
				id: '',
				firstName: this.userProvider.loggedInUser.firstName
					? this.userProvider.loggedInUser.firstName
					: '',
				lastName: this.userProvider.loggedInUser.lastName
					? this.userProvider.loggedInUser.lastName
					: '',
				address: this.userProvider.loggedInUser.lastName
					? this.userProvider.loggedInUser.addressLine1
					: '',
				city: this.userProvider.loggedInUser.lastName
					? this.userProvider.loggedInUser.addressCity
					: '',
				state: this.userProvider.loggedInUser.lastName
					? this.userProvider.loggedInUser.addressState
					: '',
				zip: this.userProvider.loggedInUser.lastName
					? this.userProvider.loggedInUser.addressZipcode
					: '',
				phone: this.userProvider.loggedInUser.lastName
					? this.userProvider.loggedInUser.phoneNumber
					: '',
			}
		}
	}

	editDefaultAddress(adr) {
		this._butClicked = true
		if (adr === 'shipping') {
			this.mealService.updateUserData = {
				page: 'shipping',
				action: 'edit',
			}
			this.newShipping = Object.assign(
				{},
				this.userProvider.loggedInUser.shipping
			)
		} else {
			this.mealService.updateUserData = {
				page: 'billing',
				action: 'edit',
			}
			this.newShipping = Object.assign(
				{},
				this.userProvider.loggedInUser.billing
			)
		}
	}

	edit(index: number) {
		this.mealService.updateUserData = { page: 'list', action: 'edit' }
		this._butClicked = true
		this.newShipping = Object.assign(
			{},
			this.userProvider.loggedInUser.shippingList[index]
		)
	}

	select(index: number) {
		this.userProvider.loggedInUser.shipping =
			this.userProvider.loggedInUser.shippingList[index]
		this.updateUser()
	}

	asBilling(index: number) {
		this.userProvider.loggedInUser.billing =
			this.userProvider.loggedInUser.shippingList[index]
		this.updateUser()
	}

	updateUser() {
		this.util.showLoading2('')
		console.log(
			'updated shipping/billing ->',
			this.userProvider.loggedInUser
		)
		this.api
			.updateUser(
				this.userProvider.loggedInUser.id,
				this.userProvider.loggedInUser
			)
			.subscribe(
				async () => {
					this.util.hideLoading2()
					//this.util.showToast('Saved Successfully...')
					console.log('new user ->', this.userProvider.loggedInUser)
					this.newShipping = {}
					this._butClicked = false
					this._asSameAdr = false
				},
				(err) => {
					this.util.hideLoading2()
					this.util.showToast(
						'Not Saved, please check your input data...'
					)
					console.log(err)
				}
			)
	}

	async del(index: number) {
		console.log(index)
		const alert = await this.util.createAlert(
			'Confirm Delete',
			false,
			'Please Confirm Deleting this item...',
			{
				text: 'Cancel',
				role: 'cancel',
				cssClass: 'secondary',
			},
			{
				text: 'Ok',
				handler: async () => {
					// check if used in default shipping or billing
					if (
						this.userProvider.loggedInUser.shipping &&
						this.userProvider.loggedInUser.shipping.id ===
							this.userProvider.loggedInUser.shippingList[index]
								.id
					) {
						this.userProvider.loggedInUser.shipping = {}
					}
					if (
						this.userProvider.loggedInUser.billing &&
						this.userProvider.loggedInUser.billing.id ===
							this.userProvider.loggedInUser.shippingList[index]
								.id
					) {
						this.userProvider.loggedInUser.billing = {}
					}
					this.userProvider.loggedInUser.shippingList.splice(index, 1)
					this.updateUser()
				},
			}
		)
		await alert.present()
	}

	async update(index: number) {
		console.log(index)
		// const alert = await this.util.createAlert(
		// 	'Confirm update',
		// 	false,
		// 	'Please Confirm updating this item...',
		// 	{
		// 		text: 'Cancel',
		// 		role: 'cancel',
		// 		cssClass: 'secondary',
		// 	},
		// 	{
		// 		text: 'Ok',
		// 		handler: async () => {
		this._butClicked = false
		this.userProvider.loggedInUser.shippingList[index] = this.newShipping
		// check if default shipping & billing are same
		if (this.newShipping.id === this.userProvider.loggedInUser.shipping.id)
			this.userProvider.loggedInUser.shipping = this.newShipping
		if (this.newShipping.id === this.userProvider.loggedInUser.billing.id)
			this.userProvider.loggedInUser.billing = this.newShipping
		this.updateUser()
		// 		},
		// 	}
		// )
		// await alert.present()
	}
}
