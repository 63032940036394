import { Component } from '@angular/core'
import { MenuController } from '@ionic/angular'
import { MealsService } from './services/meals/meals.service'
import { UserProviderService } from './services/userProvider/user-provider.service'
import { UtilService } from './services/util/util.service'

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent {
	links = [
		{
			name: 'Delivery Schedule',
			location: 'delivery-schedule',
			check: true,
			icon: 'alarm-outline',
		},

		{
			name: 'Order History',
			location: 'orders',
			check: true,
			icon: 'receipt-outline',
		},

		{
			name: 'Profile',
			location: 'profile',
			check: true,
			icon: 'person-outline',
		},

		{
			name: 'Payment Methods',
			location: 'BillingPage',
			check: true,
			icon: 'card-outline',
		},
		{
			name: 'Chefs',
			location: 'chefs',
			check: false,
			icon: 'people-outline',
		},
	]

	constructor(
		private util: UtilService,
		public userProvider: UserProviderService,
		private menu: MenuController,
		private mealService: MealsService
	) {}

	async ngOnInit() {
		console.log('init verif -=-=-=-=-=--=-=---=-=')
		this.mealService.checkUserStatus()

	}

	gotoPage(page) {
		let modals = ['HowPage', 'ReferPage', 'HistoryPage', 'BillingPage']
		if (modals.includes(page)) {
			this.mealService.openModal(page)
		} else this.util.navigateForward(page)
	}

	async logout() {
		const alert = await this.util.createAlert(
			'Logout',
			false,
			'Please confirm your log out?',
			{
				text: 'Cancel',
				role: 'cancel',
				cssClass: 'secondary',
				handler: (res) => {},
			},
			{
				text: 'Yes',
				handler: async () => {
					this.mealService.initAllData()
					this.util.navigateRoot('/auth')
					this.userProvider.logout()
				},
			}
		)

		await alert.present()
	}

	navigate(url: string, loginMode: boolean) {
		this.userProvider.loginMode = loginMode
		this.util.navigateForward(url)
	}
}
