import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { from, Observable, of, take } from 'rxjs'
import { AuthenticationService } from './firebase-authentication.service'
import { FirestoreService } from './firestore.service'
import { environment } from 'environments/environment'
import 'firebase/firestore'

@Injectable({
	providedIn: 'root',
})
export class ApiService {
	defaultZipCode = '92618'
	constructor(
		private auth: AuthenticationService,
		private firestore: FirestoreService,
		private http: HttpClient
	) {}

	postHttpData(url: string, body: any): Promise<any[]> {
		return new Promise<any>((resolve, reject) => {
			const httpOptions = {
				headers: new HttpHeaders({
					api_key: environment.tangyApiKey,
				}),
			}

			try {
				this.http
					.post<any[]>(url, body, httpOptions)
					.pipe()
					.subscribe({
						next: () => {
							console.log('next.....')
							resolve(true)
						},
						error: (e: any) => {
							console.log('error.....', e)
							reject(e)
						},
						complete: () => console.log('completed.....'),
					})
			} catch (e) {
				reject(e)
			}
		})
	}

	/**-------------------------------------
	 *
	 * @param phoneNumber
	 * @returns
	 */
	sendOtp(phoneNumber: string) {
		return new Promise<any>((resolved, rejected) => {
			if (!phoneNumber) {
				rejected('No phone number, please try again')
			}
			const url = environment.tangyApiBase + 'sendOTP'
			const data = {
				number: phoneNumber.replace(/-/g, ''),
			}
			const httpOptions = {
				headers: new HttpHeaders({
					api_key: environment.tangyApiKey,
				}),
			}
			this.http
				.post<any>(url, data, httpOptions)
				.subscribe(resolved, rejected)
		})
	}

	getOrders(userId, recNbr): Observable<any> {
		return this.firestore.find('orders', (ref) =>
			ref
				.where('customer.id', '==', userId)
				.orderBy('createdAt', 'desc')
				.limit(recNbr)
		)
	}

	getOrdersByUserId(userId, recNbr): Observable<any> {
		return this.firestore.find('orders', (ref) =>
			ref
				.where('userId', '==', userId)
				.orderBy('createdAt', 'desc')
				.limit(recNbr)
		)
	}

	getOrder(id: string): Observable<any> {
		return this.addIdToObject(id, this.firestore.getOne('orders', id))
	}

	getVendor(vendorId): Observable<any> {
		return this.firestore.find('vendors', (ref) =>
			ref.where('id', '==', vendorId)
		)
	}

	getMeals(vendorId): Observable<any> {
		return this.firestore.find('meals', (ref) =>
			ref
				.where('vendorId', '==', vendorId)
				.where('active', '==', true)
				.orderBy('createdAt', 'desc')
		)
	}

	getMeal(id: string): Observable<any> {
		return this.addIdToObject(id, this.firestore.getOne('meals', id))
	}

	getPendingOrders(userId, recNbr): Observable<any> {
		return this.firestore.find('orders', (ref) =>
			ref
				.where('userId', '==', userId)
				.where('request_completed', '==', false)
				.where('request_timeout', '==', false)
				.orderBy('createdAt', 'desc')
				.limit(recNbr)
		)
	}

	getList(collection): Observable<any> {
		return this.firestore.find(collection, (ref) =>
			ref.orderBy('createdAt', 'desc')
		)
	}

	saveDataApi(collection: string, data: any): Observable<any> {
		return from(this.firestore.create(collection, data))
	}

	getZoneList(collection): Observable<any> {
		return this.firestore.find(collection, (ref) => ref)
	}

	getActiveVendorsList(collection): Observable<any> {
		return this.firestore.find(collection, (ref) =>
			ref.where('active', '==', true).where('blocked', '==', false)
		)
	}

	getActiveList(collection): Observable<any> {
		return this.firestore.find(collection, (ref) =>
			ref.where('active', '==', true).orderBy('order', 'asc')
		)
	}

	updateData(collection, id, data): Observable<any> {
		console.log('upadteDate ->', collection, id, data)
		return from(this.firestore.update(collection, id, data))
	}

	/**
	 * @param collection
	 * @param id
	 * @param data
	 * @returns
	 */
	updateOne(collection, id, data) {
		return new Promise(async (resolve, reject) => {
			from(this.firestore.update(collection, id, data)).subscribe(
				resolve,
				reject
			)
		})
	}

	updateUser(id, userData): Observable<any> {
		return from(this.firestore.update('users', id, userData))
	}

	getOneRec(collection: string, id: string) {
		return new Promise<any>((resolve, reject) => {
			this.addIdToObject(
				id,
				this.firestore.getOne(collection, id)
			).subscribe(resolve, reject)
		})
	}

	getUser(id): Observable<any> {
		return this.addIdToObject(id, this.firestore.getOne('users', id))
	}

	getUserByEmail(email) {
		return new Promise(async (resolve, reject) => {
			from(
				this.firestore.find('users', (ref) =>
					ref.where('email', '==', email)
				)
			).subscribe(resolve, reject)
		})
	}

	submitData(collection, data: any): Observable<any> {
		return this.snapshotToDataConverter(
			this.firestore.create(collection, data)
		)
	}

	addIdToObject(id, obj: Observable<any>) {
		return new Observable((observer) => {
			if (id) {
				obj.subscribe(
					(ref) => {
						const newObj = ref
						if (newObj) {
							newObj.id = id
						}
						observer.next(newObj)
					},
					(err) => {
						observer.error(err)
					}
				)
			} else {
				observer.error({ message: 'No ID' })
			}
		})
	}

	signUp(user): Promise<any> {
		const userInfo = {
			id: '',
			firstName: user.firstName,
			lastName: user.lastName,
			email: user.email,
			phoneNumber: user.phoneNumber,
			addressZipcode: user.addressZipcode,
		}

		return new Promise<any>((reseolved, rejected) => {
			this.auth
				.createAccount(user.email, user.password)
				.then(async (userWithId: any) => {
					userInfo.id = userWithId.uid
					this.login(userInfo.email, user.password).then((login) => {
						console.log('Login Stuff -> ', login)
						this.firestore
							.createWithId('users', userInfo)
							.then(async (usr) => reseolved(userInfo))
					})
				})
				.catch((err) => {
					rejected(err)
				})
		})
	}

	login(username: string, password: string): Promise<any> {
		return new Promise<any>((resolved, rejected) => {
			this.auth
				.login(username, password)
				.then((user) => resolved({ id: user.uid }))
				.catch((err) => rejected(err))
		})
	}

	snapshotToDataConverter(query: Promise<any>) {
		return new Observable((observer) => {
			query
				.then((ref) => {
					const obj = ref.data()
					obj.id = ref.id
					observer.next(obj)
				})
				.catch((err) => {
					observer.error(err)
				})
		})
	}

	/**----------------------------------------------------------------------- send email
	 *
	 * @param data
	 * @returns
	 */
	sendTangyEmail(data: any) {
		return new Promise<any>((resolved, rejected) => {
			if (!data.email) {
				rejected('Wrong Email, please refresh browser')
			}
			const url = environment.tangyApiBase + 'sendEmail'
			console.log('send email ->', data, url)
			const httpOptions = {
				headers: new HttpHeaders({
					api_key: environment.tangyApiKey,
				}),
			}

			this.http
				.post<any>(url, data, httpOptions)
				.subscribe(resolved, rejected)
		})
	}

	/**----------------------------------------------------------------------- send email
	 *
	 * @param data
	 * @returns
	 */
	sendWelcEmail(endPoint: string, data: any) {
		return new Promise<any>((resolved, rejected) => {
			if (!data.email) {
				rejected('Wrong Email, please refresh browser')
			}
			const url = environment.tangyApiBase + endPoint
			console.log('send email ->', data, url)
			const httpOptions = {
				headers: new HttpHeaders({
					api_key: environment.tangyApiKey,
				}),
			}

			this.http
				.post<any>(url, data, httpOptions)
				.subscribe(resolved, rejected)
		})
	}

	/**----------------------------------------------------------------------- send email
	 *
	 * @param data
	 * @returns
	 */
	sendActivationLink(endPoint: string, data: any) {
		return new Promise<any>((resolved, rejected) => {
			if (!data.email) {
				rejected('Wrong Email, please refresh browser')
			}
			const url = environment.tangyApiBase + endPoint
			const httpOptions = {
				headers: new HttpHeaders({
					api_key: environment.tangyApiKey,
				}),
			}

			this.http
				.post<any>(url, data, httpOptions)
				.subscribe(resolved, rejected)
		})
	}

	/**
	 *
	 * @param name
	 * @param email
	 */
	async sendEmail(name: string, email: string, message: string) {
		const data = {
			name: name, // To
			email: email, // To
			senderName: 'Tangy Chef', // from
			subject: 'Order Placed',
			message: message,
		}

		try {
			this.sendTangyEmail(data).then(
				(el) => {
					console.log('email sent!')
				},
				(e) => {
					console.log('email not sent', e)
				}
			)
		} catch (e) {
			console.log('email not sent', e)
		}
	}

	/**-----------------------------------------------------------------------------------send SMS
	 *
	 * @param phoneNumber
	 * @param sms
	 * @returns
	 */
	sendTangySMS(phoneNumber, message) {
		return new Promise<any>((resolved, rejected) => {
			if (!phoneNumber) {
				rejected('No phone number, please try again')
			}
			const url = environment.tangyApiBase + 'sendSMS'
			const data = {
				number: phoneNumber.replace(/-/g, ''),
				message,
			}
			console.log('send sms data', url, data)
			const httpOptions = {
				headers: new HttpHeaders({
					api_key: environment.tangyApiKey,
				}),
			}
			this.http
				.post<any>(url, data, httpOptions)
				.subscribe(resolved, rejected)
		})
	}

	/**
	 *
	 * @param phone
	 * @param msg
	 */
	async sendSMS(number, message: string) {
		try {
			this.sendTangySMS(number, message).then(
				(el) => {
					console.log('SMS sent to -->', number)
				},
				(e) => {
					console.log('SMS failed to send-->', e)
				}
			)
		} catch (e) {
			console.log('SMS failed to send-->', e)
		}
	}

	// create order on square

	/**
	 *
	 * @param dt
	 * @param myKey
	 * @returns
	 */

	createOrder(dt: any, myKey: string): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			const httpUrl = environment.tangyApiBase + 'square/order'

			const httpOptions = {
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					Accept: '*/*',
					async: 'true',
					crossDomain: 'true',
					'Access-Control-Allow-Origin': '*',
					'cache-control': 'no-cache',
					api_key: myKey,
				}),
				params: dt,
			}

			this.http.post<any>(httpUrl, dt, httpOptions).subscribe({
				next(res: any) {
					resolve(res)
				},
				error(e: any) {
					reject(e)
				},
			})
		})
	}
	// make payment to square

	/**
	 * ************************************************** Create payment
	 *
	 * @param dt
	 * @param myKey
	 * @returns
	 */

	makePayment(dt: any, myKey: string): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			const httpUrl = environment.tangyApiBase + 'square/payment'
			const httpOptions = {
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					Accept: '*/*',
					async: 'true',
					crossDomain: 'true',
					'Access-Control-Allow-Origin': '*',
					'cache-control': 'no-cache',
					api_key: myKey,
				}),
				params: dt,
			}
			console.log('api header }}}}}}}}}->', { httpUrl, dt, httpOptions })
			this.http.post<any>(httpUrl, dt, httpOptions).subscribe({
				next(res: any) {
					resolve(res)
				},
				error(e: any) {
					reject(e)
				},
			})
		})
	}

	/**----------------------------------------------------------------------- send email
	 *
	 * @param data
	 * @returns
	 */
	getActiveVendorsListByZip(data) {
		return new Promise<any>((resolved, rejected) => {
			const url = environment.tangyApiBase + 'tangy-vendors/filters'
			console.log('filters data ->', data, url)
			const httpOptions = {
				headers: new HttpHeaders({
					api_key: environment.tangyApiKey,
				}),
			}

			this.http
				.post<any>(url, data, httpOptions)
				.subscribe(resolved, rejected)
		})
	}

	/**-------------------------------------------- find user zip code
	 *
	 * @param lat
	 * @param lon
	 * @returns
	 */
	getLocation(lat: number, lon: number) {
		const apiKey = 'AIzaSyAfQXr85FY6ObZbQjXvaUn_-UKPUrVvzIM'
		const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${apiKey}`
		return new Promise<any>((resolve, reject) => {
			this.http.get(url).subscribe((data: any) => {
				const addressComponents = data.results[0]?.address_components
					? data.results[0].address_components
					: ''
				if (addressComponents) {
					const zipCode = addressComponents.find((component: any) =>
						component.types.includes('postal_code')
					).long_name
					console.log('ZIP Code:', zipCode)
					if (zipCode) resolve(zipCode)
					else resolve(this.defaultZipCode)
				} else resolve(this.defaultZipCode)
			})
		})
	}

	/**----------------------------------------- find user's lat lng
	 *
	 */
	async getZipCode(): Promise<string> {
		return new Promise<string>((resolve, reject) => {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition((position) => {
					const lat = position.coords.latitude
					const lon = position.coords.longitude
					console.log('Latitude:', lat, 'Longitude:', lon)
					resolve(this.getLocation(lat, lon))
				})
			} else {
				console.log('Geolocation is not supported by this browser.')
				resolve(this.defaultZipCode)
			}
		})
	}

	getZipCode2(): Promise<any> {
		return new Promise<string>((resolve, reject) => {
			// this.geolocation$.pipe(take(1)).subscribe(
			// 	(pos) => {
			// 		const lat = pos.coords.latitude
			// 		const lon = pos.coords.longitude
			// 		console.log('Latitude:', lat, 'Longitude:', lon)
			// 		resolve(this.getLocation(lat, lon))
			// 	},
			// 	(err) => {
			// 		console.log('Geolocation is not supported by this browser.')
			// 		resolve('')
			// 	}
			// )
		})
	}
}
