<div class="my-topnav" *ngIf="!mobile">
	<div
		[routerLink]="['/dishes']"
		(click)="mealService._searchClicked = false; mealService.resetSearch()"
	>
		HOME
	</div>
	<div [routerLink]="['/browse']">BROWSE</div>
	<div (click)="openChefs()">CHEFS</div>
	<div><a href="https://vendor.tangychef.com">Sell With Us</a></div>

	<!-- Profile picture with dropdown -->
	<div class="profile-container" *ngIf="userProvider?.loggedInUser?.id">
		<img
			[src]="
				userProvider?.loggedInUser?.profileImage
					? userProvider?.loggedInUser?.profileImage +
					  ' | image | async '
					: 'assets/icon/profileIcon.png'
			"
			onerror="this.onerror=null;this.src='assets/icon/profileIcon.png';"
			alt="Profile Picture"
			class="profile-pic"
			(click)="toggleDropdown()"
		/>
		<div class="dropdown-content" *ngIf="dropdownOpen">
			<div [routerLink]="['/profile']" class="drplist">PROFILE</div>
			<div [routerLink]="['/orders']" class="drplist">ORDERS</div>
		</div>
	</div>

	<div
		[routerLink]="['/auth']"
		*ngIf="!userProvider?.loggedInUser?.id"
		style="width: 60px"
	>
		SIGN IN
	</div>

	<section
		(click)="myCart()"
		class="meal-Counter-2 relative cursor"
		*ngIf="mealService?.cartCount > 0"
	>
		<ion-icon name="cart-outline" class="f1-3"> </ion-icon>
		<section class="my-web-cart">{{ mealService.cartCount }}</section>
	</section>
</div>
