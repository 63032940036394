export const environment = {
	production: false,

	firebaseConfig: {
		apiKey: 'AIzaSyCuFmnjPX9Sp3xERks3rQfTQjIYNUoercM',
		authDomain: 'tangy-chef.firebaseapp.com',
		projectId: 'tangy-chef',
		storageBucket: 'tangy-chef.firebasestorage.app',
		messagingSenderId: '211680630603',
		appId: '1:211680630603:web:00b843408023039ecdbace',
		measurementId: 'G-2W62FTQ3DC',
	},

	stripe: {
		publishable:
			'pk_test_51Q8YoxCsphgUbwAhRPkGcllzq7G5sEehik4L5egYimhkWJglJbzx8lVzJg1xQCR98KxcWTPwxuysu7jTeOLrJqMf00lX3AjkVO',
		secret: 'sk_test_51Q8YoxCsphgUbwAhMNFIeVJo6q5umMjdIQnd8ghjbG4nrzKKI2JBzFtZwHJEEkeqshT6Ad32pwbGgkqtONfuu3T600F6Lq4czj',
	},

	JWTKey: 'eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJOU1RfS0VZIjoiNnY5eSRCJkUpSEBNY1FmVGpXblpyNHU3dyF6JUMqRi1KYU5kUmdVa1hwMnM1djh5L0E_RChHK0tiUGVTaFZtWSIsIm5hbWUiOiJWYWRpbSJ9.qhx5TbhInLNLPSS9bFWdzt-T_XXdMZCjLBgzdXmD3LiHpcI7Luv_H1ynFnL3ARZY5pcB58kSU6Z6o9ZzDTP5Sw',
	tangyApiKey: 'f488c628a5fdf8b17861bb8d4947a5b3eb00d2f8',
	radius: 25,

	tangyApiBase: 'https://tangy-api-e87fcd0feaa8.herokuapp.com/api/',
	tangyVerifEmailURL: 'https://app.qa.tangychef.com/',
	appLink: 'https://app.qa.tangychef.com/',

	// tangyApiBase: 'http://localhost:3000/api/',
	// tangyVerifEmailURL: 'http://localhost:8100/',
	// appLink: 'http://localhost:8100/',
}
