import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import {
	AlertController,
	LoadingController,
	NavController,
	ToastController,
} from '@ionic/angular'
import { Storage } from '@ionic/storage-angular'
import * as geolib from 'geolib'
declare let google

@Injectable({
	providedIn: 'root',
})
export class UtilService {
	loading: HTMLIonLoadingElement
	loading2: HTMLIonLoadingElement
	loadCounter = 0
	constructor(
		private navCtrl: NavController,
		private toastController: ToastController,
		private alertController: AlertController,
		private storage: Storage,
		private loadingCtrl: LoadingController,
		private router: Router
	) {}

	async getDistanceBtw2points(a: any, b: any) {
		// a, b = { latitude: .., longitude: ... }
		const distance = await geolib.getPreciseDistance(a, b)
		return distance
	}

	async convertToMiles(d) {
		console.log('distance ----->', d)
		return (0.621371 * d) / 1000

		//const dist = geolib.convertDistance(d, 'mi')
		//return Math.round(10 * dist) / 10
	}

	/**----------------------------------------------
	 *
	 * @param message
	 */
	async presentToast(message: string) {
		const toast = await this.toastController.create({
			header: message,
			position: 'middle',
			duration: 2500,
			cssClass: 'toast-scheme',
			buttons: [
				{
					text: 'Ok',
					role: 'cancel',
					handler: () => {
						// console.log('Cancel clicked');
					},
				},
			],
		})
		toast.present()
	}

	showToast(message: string) {
		const toast = this.presentToast(message)
	}

	/**-------------------------------------------------------
	 *
	 * @param header
	 * @param backdropDismiss
	 * @param message
	 * @param buttonOptions1
	 * @param buttonOptions2
	 * @returns
	 */
	async createAlert(
		header,
		backdropDismiss,
		message,
		buttonOptions1,
		buttonOptions2?
	): Promise<HTMLIonAlertElement> {
		const alert = await this.alertController.create({
			header,
			backdropDismiss,
			message,
			buttons: !buttonOptions2
				? [buttonOptions1]
				: [buttonOptions1, buttonOptions2],
		})
		return alert
	}

	async alert(title, msg) {
		const alert = await this.createAlert(title, false, msg, {
			text: 'Ok',
			role: 'ok',
			cssClass: 'secondary',
			handler: () => {},
		})
		await alert.present()
	}

	/**-----------------------------------------------
	 *
	 * @param message
	 * @param showCloseButton
	 * @param position
	 * @param duration
	 * @returns
	 */
	async createToast(
		message,
		showCloseButton = false,
		position = 'bottom' as 'top' | 'bottom' | 'middle',
		duration = 2500
	): Promise<HTMLIonToastElement> {
		const toast = await this.toastController.create({
			message,
			position: 'middle',
			duration,
			buttons: [
				{
					text: 'Ok',
					role: 'cancel',
					handler: () => {
						// console.log('Cancel clicked');
					},
				},
			],
		})
		return toast
	}

	async createToastMsg(message: string) {
		const toast = await this.createToast(message)
		await toast.present()
	}

	/**---------------------------------------------------
	 *
	 * @param url
	 * @param options
	 */
	navigateRoot(url, options?) {
		this.navCtrl.navigateRoot(url, options)
	}
	navigateForward(url, options?) {
		this.navCtrl.navigateForward(url, options)
	}
	navigateBack(url, options?) {
		this.navCtrl.navigateBack(url, options)
	}

	navigateByUrl(url: string, options?: any) {
		this.router.navigateByUrl(url)
	}

	back() {
		this.navCtrl.back()
	}

	async getDataStorage(name: string, from: string) {
		const v = localStorage.getItem(name)
		console.log(`get localstorage from ${from} val:  ${name} -->`, v)
		return v ? v : ''
	}

	async setDataStorage(name: string, val: string, from: string) {
		localStorage.setItem(name, val)
		//check saved value
		const v = localStorage.getItem(name)
		console.log(`set localstorage from ${from}  val:  ${name}  -->`, v)
	}

	async getStorage(name: string): Promise<any> {
		return name ? await this.storage.get(name) : ''
	}

	async setStorage(name: string, val: string) {
		await this.storage.set(name, val)
	}

	async getGooglePlaceAutoCompleteList(searchText, geolocation, country) {
		const service = new window['google'].maps.places.AutocompleteService()
		let pred
		// var circle = new google.maps.Circle(
		//     {center: geolocation, radius: 10000});
		// autocomplete.setBounds(circle.getBounds());
		await new Promise((resolve, reject) => {
			service.getPlacePredictions(
				{
					input: searchText,
					componentRestrictions: {
						country: country,
					},
				},
				(predictions) => {
					pred = predictions
					resolve(true)
				}
			)
		})
		return pred
	}

	convertAddressToLatLng(address: string) {
		const geocoder = new google.maps.Geocoder()
		return new Promise<any>((resolve, reject) => {
			geocoder.geocode({ address }, function (results, status) {
				if (
					status === google.maps.GeocoderStatus.OK &&
					results &&
					!results.err
				) {
					const latitude = results[0].geometry.location.lat()
					const longitude = results[0].geometry.location.lng()
					resolve({ lat: latitude, lng: longitude })
				} else {
					resolve('Error reversing location results')
				}
			})
		})
	}

	async getGeoCodedAddress(lat: number, lng: number) {
		let block, street, building, country
		if (navigator.geolocation) {
			const geocoder = await new google.maps.Geocoder()
			const latlng = await new google.maps.LatLng(lat, lng)
			const request = { latLng: latlng }
			console.log('request =-=>', request)

			await new Promise((resolve, reject) => {
				geocoder.geocode(request, (results, status) => {
					if (status === google.maps.GeocoderStatus.OK) {
						const result = results[0]
						const rsltAdrComponent = result.address_components
						if (result !== null) {
							if (rsltAdrComponent[0] !== null) {
								block = rsltAdrComponent[0].long_name
								street = rsltAdrComponent[2].short_name
								building = rsltAdrComponent[1].short_name
							}

							let local_add_1 = ''
							let local_add_2 = ''
							for (let i = 0; i < rsltAdrComponent.length; i++) {
								if (
									rsltAdrComponent[i].types &&
									rsltAdrComponent[i].types.includes(
										'country'
									)
								) {
									country = rsltAdrComponent[i].short_name
								}
								if (
									rsltAdrComponent[i].types &&
									rsltAdrComponent[i].types.includes(
										'administrative_area_level_1'
									)
								) {
									local_add_1 = rsltAdrComponent[i].short_name
								}
								if (
									rsltAdrComponent[i].types &&
									rsltAdrComponent[i].types.includes(
										'locality'
									)
								) {
									local_add_2 = rsltAdrComponent[i].short_name
								}
							}
							resolve(true)
						} else {
							alert('No address available!')
						}
					}
				})
			})
		}
		return { block, street, building, country }
	}

	reverseAddressSearch(address: string) {
		console.log('address -> ', address)
		const geocoder = new google.maps.Geocoder()
		return new Promise<any>((resolve, reject) => {
			geocoder.geocode({ address }, function (results, status) {
				if (status === google.maps.GeocoderStatus.OK) {
					if (results) {
						results = results[0]
						if (results && !results.err) {
							const rsltAdrComponent = results.address_components
							let number
							let street
							let city
							let state
							let zipcode

							for (let i = 0; i < rsltAdrComponent.length; i++) {
								if (
									rsltAdrComponent[i].types &&
									rsltAdrComponent[i].types.includes(
										'street_number'
									)
								) {
									number = rsltAdrComponent[i].short_name
								}
								if (
									rsltAdrComponent[i].types &&
									rsltAdrComponent[i].types.includes('route')
								) {
									street = rsltAdrComponent[i].short_name
								}
								if (
									rsltAdrComponent[i].types &&
									rsltAdrComponent[i].types.includes(
										'locality'
									)
								) {
									city = rsltAdrComponent[i].short_name
								}
								if (
									rsltAdrComponent[i].types &&
									rsltAdrComponent[i].types.includes(
										'postal_code'
									)
								) {
									zipcode = rsltAdrComponent[i].short_name
								}
								if (
									rsltAdrComponent[i].types &&
									rsltAdrComponent[i].types.includes(
										'administrative_area_level_1'
									)
								) {
									state = rsltAdrComponent[i].short_name
								}
							}
							resolve({ number, street, city, state, zipcode })
						}
					}
				} else {
					resolve('Error reversing location results')
				}
			})
		})
	}

	async showAlert(header, msg) {
		const alert = await this.createAlert(header, false, msg, {
			text: 'Ok',
		})
		await alert.present()
	}

	async showLoading(message: string) {
		this.loading = await this.loadingCtrl.create({
			message,
			duration: 3000,
		})
		await this.loading.present()
	}

	async hideLoading() {
		setTimeout(() => {
			if (this.loading) this.loading.dismiss()
		}, 100)
		return
	}

	async showLoading2(message: string) {
		this.loading2 = await this.loadingCtrl.create({
			message,
		})
		await this.loading2.present()
	}

	async hideLoading2() {
		setTimeout(() => {
			if (this.loading2) this.loading2.dismiss()
		}, 100)
		return
	}

	async handleError(error): Promise<void> {
		console.log(
			'loader err >>>>>>>>>>>>>>',
			error.message.replace('Firebase:', '').trim()
		)
	}

	formatPhone($event: any) {
		let phone = $event.target.value.replace(/[^\d]/g, '')
		const len = phone.length
		phone = len > 10 ? phone.substr(0, 10) : phone
		phone =
			len > 3 ? phone.substr(0, 3) + '-' + phone.substr(3, len) : phone
		phone =
			len > 6
				? phone.substr(0, 7) + '-' + phone.substr(7, len + 1)
				: phone

		return phone
	}

	async createErrorAlert(title, message) {
		if (title && message) {
			const alert = await this.createAlert(title, false, message, {
				text: 'Ok',
				handler: async () => {},
			})
			await alert.present()
		}
	}
}
