<div (click)="mealService.dismiss()" class="close-modal">
	<ion-icon name="close"></ion-icon>
</div>

<ion-content #top>
	<div id="pageExploreMeals" class="container width-perc">
		<h5 class="center">
			<strong *ngIf="!_butClicked">
				{{mealService.updateUserData.page == 'billing' ||
				mealService.updateUserData.page == 'shipping' ||
				mealService.updateUserData.page == 'list' ? 'Shipping / Billing
				Address' : 'Payment Method'}}
			</strong>
			<strong *ngIf="_butClicked">
				{{mealService.updateUserData.action == 'edit' ? 'Edit' : 'Add'}}
				{{mealService.updateUserData.page == 'shipping' ? 'Shipping
				Address' : mealService.updateUserData.page == 'billing' ?
				'Billing Address' : mealService.updateUserData.page == 'list' ?
				'Address' : 'Payment Method'}}
			</strong>
		</h5>

		<div style="margin-bottom: 4rem">
			<ion-row>
				<ion-col
					*ngIf="_butClicked"
					size-lg="10"
					size-md="12"
					size-sm="12"
					size-xs="12"
					style="margin: 1rem auto"
				>
					<form *ngIf="_butClicked" #myform="ngForm">
						<ion-item
							class="small-font-9 orange-dark"
							style="width: 275px; margin-left: 1.4rem"
							*ngIf="mealService.updateUserData.action == 'add'"
						>
							<ion-label>
								Same as
								<span class="black"
									>{{mealService.updateUserData.page ==
									'shipping' ? 'billing' : 'shipping'}}</span
								>
								address
							</ion-label>
							&nbsp;
							<ion-checkbox
								name="shipping"
								color="warning"
								[(ngModel)]="_asSameAdr"
							></ion-checkbox>
						</ion-item>

						<div class="container">
							<div>
								<ion-item>
									<ion-label
										for="firstName"
										position="floating"
										>First Name</ion-label
									>
									<ion-input
										name="firstName"
										type="text"
										required
										ngModel
										minlength="2"
										[(ngModel)]="newShipping.firstName"
									></ion-input>
								</ion-item>

								<ion-item>
									<ion-label or="lastName" position="floating"
										>Last Name</ion-label
									>
									<ion-input
										name="lastName"
										type="text"
										minlength="2"
										required
										ngModel
										[(ngModel)]="newShipping.lastName"
									></ion-input>
								</ion-item>
							</div>

							<div id="autoComplete">
								<ion-item>
									<ion-label for="address" position="floating"
										>Address</ion-label
									>
									<ion-input
										name="address"
										type="text"
										minlength="1"
										required
										ngModel
										[(ngModel)]="newShipping.address"
										(ionInput)="search($event)"
									></ion-input>
								</ion-item>
								<div
									id="autoCompleteList"
									*ngIf="autoCompleteItems.length > 0"
									#outsideListener
								>
									<div
										class="auto-complete-item border-bottom"
										*ngFor="let item of autoCompleteItems"
										(click)="chooseItem(item)"
									>
										<div class="line-1">
											<ion-icon
												name="location"
												color="danger"
											></ion-icon>
											{{item.description[0]}}
										</div>
										<div class="line-2">
											{{item.description[1]}}
										</div>
									</div>
								</div>
							</div>

							<ion-item>
								<ion-label for="city" position="floating"
									>City</ion-label
								>
								<ion-input
									name="city"
									type="text"
									minlength="1"
									required
									ngModel
									[(ngModel)]="newShipping.city"
									class="grey-text"
								></ion-input>
							</ion-item>

							<ion-item>
								<ion-label for="state" position="floating"
									>State</ion-label
								>
								<ion-input
									name="state"
									type="text"
									minlength="2"
									maxlength="2"
									required
									ngModel
									[(ngModel)]="newShipping.state"
									class="grey-text"
								></ion-input>
							</ion-item>

							<ion-item>
								<ion-label for="zip" position="floating"
									>Zip Code</ion-label
								>
								<ion-input
									name="zipcode"
									type="text"
									minlength="5"
									maxlength="5"
									required
									ngModel
									[(ngModel)]="newShipping.zip"
									class="grey-text"
								></ion-input>
							</ion-item>

							<ion-item>
								<ion-label for="phone" position="floating"
									>Phone #</ion-label
								>
								<ion-input
									name="phone"
									(input)="formatPhone($event)"
									type="tel"
									minlength="12"
									maxlength="12"
									required
									ngModel
									[(ngModel)]="newShipping.phone"
								></ion-input>
							</ion-item>

							<div id="submitButton">
								<ion-row class="flex-row-2">
									<ion-col>
										<button
											type="submit"
											class="custom-btn"
											[ngClass]="myform.valid ? '' : 'invalid'"
											[disabled]="myform.valid ? false : true"
											(click)="save()"
										>
											<span>Save</span>
										</button>
									</ion-col>
									<ion-col>
										<button
											class="btn-link"
											(click)="cancel()"
										>
											<span>Cancel</span>
										</button>
									</ion-col>
								</ion-row>
							</div>
						</div>
					</form>
				</ion-col>

				<ion-col
					*ngIf="!_butClicked"
					size-lg="10"
					size-md="12"
					size-sm="12"
					size-xs="12"
					style="margin: 1rem auto"
				>
					<div>
						<span class="h">Your default shipping address</span>
						<div class="line-left"></div>
					</div>

					<div class="col-box padding-2 margin-top-2">
						{{ userProvider?.loggedInUser?.shipping?.firstName ?
						userProvider?.loggedInUser?.shipping?.firstName :
						'Please add your shipping address.' }}
						{{userProvider?.loggedInUser?.shipping?.lastName ?
						userProvider?.loggedInUser?.shipping?.lastName : ''}}

						<div
							*ngIf="userProvider?.loggedInUser?.shipping?.address"
						>
							{{userProvider?.loggedInUser?.shipping?.address}}
							<br />{{userProvider?.loggedInUser?.shipping?.city}},
							{{userProvider?.loggedInUser?.shipping?.state}}
							{{userProvider?.loggedInUser?.shipping?.zip}}
							<br />
							{{userProvider?.loggedInUser?.shipping?.phone ?
							'Phone # ' +
							userProvider?.loggedInUser?.shipping?.phone : ''}}
						</div>

						<button
							type="submit"
							class="btn-link margin-top-1"
							(click)="editDefaultAddress('shipping')"
							*ngIf="userProvider?.loggedInUser?.shipping?.firstName"
						>
							<span>Edit</span>
						</button>
						&nbsp;

						<button
							type="submit"
							class="btn-link"
							(click)="add('shipping')"
						>
							<span>New</span>
						</button>
					</div>

					<div class="margin-top-3">
						<span class="h">Your default billing address</span>
						<div class="line-left"></div>
					</div>

					<div class="col-box padding-2 margin-top-2">
						{{ userProvider?.loggedInUser?.billing?.firstName ?
						userProvider?.loggedInUser?.billing?.firstName : 'Please
						add your billing address.' }}
						{{userProvider?.loggedInUser?.billing?.lastName ?
						userProvider?.loggedInUser?.billing?.lastName : ''}}

						<div
							*ngIf="userProvider?.loggedInUser?.billing?.address"
						>
							{{userProvider?.loggedInUser?.billing?.address}}
							<br />{{userProvider?.loggedInUser?.billing?.city}},
							{{userProvider?.loggedInUser?.billing?.state}}
							{{userProvider?.loggedInUser?.billing?.zip}}
							<br />
							{{userProvider?.loggedInUser?.billing?.phone ?
							'Phone # ' +
							userProvider?.loggedInUser?.billing?.phone : ''}}
						</div>

						<button
							type="submit"
							class="btn-link margin-top-2"
							(click)="editDefaultAddress('billing')"
							*ngIf="userProvider?.loggedInUser?.shipping?.firstName"
						>
							<span>Edit</span>
						</button>
						&nbsp;

						<button
							type="submit"
							class="btn-link"
							(click)="add('billing')"
						>
							<span>New </span>
						</button>
					</div>

					<div
						class="margin-top-3"
						*ngIf="userProvider.loggedInUser.shippingList.length "
					>
						<span class="h">Address List</span>
						<div class="line-left"></div>
					</div>

					<div
						class="col-box padding-2 margin-top-2"
						*ngIf="userProvider.loggedInUser.shippingList.length >0"
					>
						<ion-radio-group
							[(ngModel)]="userProvider.loggedInUser.shipping.id"
						>
							<ion-list>
								<div
									class="item-row border-bottom"
									*ngFor="let p of userProvider.loggedInUser.shippingList; let j=index"
								>
									<ion-row>
										<ion-col size="5" class="flex-row">
											<ion-radio
												(click)="select(j)"
												value="{{p?.id}}"
											></ion-radio>

											<ion-icon
												(click)="del(j)"
												style="
													color: rgb(170, 0, 0);
													font-size: var(
														--ion-font-body-big
													);
													margin-left: 1rem;
												"
												name="close"
											>
											</ion-icon>
										</ion-col>
										<ion-col size="7">
											{{p?.firstName}} {{p?.lastName}}
											<br />{{p?.address}} {{p?.city}},
											<br />{{p?.state}} {{p?.zip}}
											<br /><button
												class="btn-link"
												(click)="edit(j)"
											>
												Change address
											</button>
											<br /><button
												class="btn-link-2"
												(click)="asBilling(j)"
											>
												Use as billing address
											</button>
										</ion-col>
									</ion-row>
								</div>
							</ion-list>
						</ion-radio-group>
					</div>
				</ion-col>
			</ion-row>
		</div>
	</div>

	<div class="flex-rowcenter" *ngIf="!_butClicked">
		<ion-button class="orange-btn" (click)="mealService.dismiss()">
			Close
		</ion-button>
	</div>
</ion-content>
