<div class="modal-pad f-regular pm">
	<div class="dish border-bottom" *ngIf="_showHeader">
		<div class="dish-header">
			<span>Payment Method</span>
			<div class="line"></div>
			<ion-icon
				(click)="mealService.dismiss()"
				class="cursor"
				name="close"
			></ion-icon>
		</div>
	</div>

	<div class="grey margin-top-2">
		All transactions are secured and encrypted
	</div>

	<div class="margin-top-1" style="max-width: 500px">
		<app-add-card></app-add-card>

		<div
			class="margin-top-2 margin-bottom-3"
			*ngIf="sce.loggedInUser.stripeCards && sce.loggedInUser.stripeCards.length > 0"
			(click)="mealService.dismiss()"
		>
			<span class="orange-dark">Choose your default payment method</span>
			<app-credit-card-item
				*ngFor="let card of sce.loggedInUser.stripeCards; let ii = index"
				[card]="card"
				[index]="ii"
				[delete]="true"
				(dism)="dismissme($event)"
				class="margin-bottom-2"
			></app-credit-card-item>
		</div>
	</div>
</div>
