import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { MealsService } from 'app/services/meals/meals.service'
import { UserProviderService } from 'app/services/userProvider/user-provider.service'
import { UtilService } from 'app/services/util/util.service'

@Component({
	selector: 'app-mob-header',
	templateUrl: './mob-header.html',
	styleUrls: ['./mob-header.scss'],
})
export class mobHeader implements OnInit {
	@Input() zip = false
	@Input() cart = true
	@Input() profile = true
	@Input() search = false
	@Input() page = ''
	@Output() stt = new EventEmitter<any>()

	constructor(
		public mealService: MealsService,
		public readonly userProvider: UserProviderService,
		public readonly util: UtilService
	) {}
	selectedOption: string = 'PICKUP' // Default selected option
	isPickupSelected: boolean = true // Flag

	ngOnInit() {
		console.log(
			'1 zip card profile ->',
			this.page,
			this.zip,
			this.cart,
			this.profile
		)
	}
	ionViewWillEnter() {
		console.log(
			'2 zip card profile ->',
			this.page,
			this.zip,
			this.cart,
			this.profile
		)
	}

	scrollToTop() {
		this.stt.emit({})
	}
}
